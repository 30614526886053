import './TimeSchedule.css'
import React, { useEffect, useRef, useState } from 'react'
import { useBookingAppointmentCursor } from '../../BookingAppointmentCursor'
import RenderCurrentTimeLine from './subcomponents/RenderCurrentTimeLine/RenderCurrentTimeLine'
import RenderAppointments from './subcomponents/RenderAppointments/RenderAppointments'
import RenderAppointmentsCheckbox from './subcomponents/RenderAppointmentsCheckbox/RenderAppointmentsCheckbox'
import { NewCheckbox } from '../../../../../../../../newcomponents'
import IcoSchedule from '../../../../../../../../component/icon/schedule'
import CreateSchedulesModal from '../CreateSchedulesModal/CreateSchedulesModal'
import PrinterIcon from '../../../../../../../../component/icon/newPrinter'
import { useDispatch } from 'react-redux'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import { AddAlert, AlertType } from '../../../../../../../../component'
import { buildDate, formatDateWithTimezone } from '../../../../../../../../utils/convertDate'
import { postQuickEmission } from '../../../../../../http'
import IcoCalendarLoop from '../../../../../../../../component/icon/calendar_loop'
import RecurringPatientModal from './subcomponents/RecurringPatientModal/RecurringPatientModal'

const HOUR_LABELS = [
  '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', 
  '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', 
  '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', 
  '19:00', '20:00', '21:00', '22:00', '23:00', '23:59'
]

const getDayOfWeek = (day) => {
  const daysOfWeek = {
    'dom.': 'DOM',
    'seg.': 'SEG',
    'ter.': 'TER',
    'qua.': 'QUA',
    'qui.': 'QUI',
    'sex.': 'SEX',
    'sáb.': 'SAB'
  };
  const dayAbbreviation = day.toLocaleDateString('pt-BR', { weekday: 'short' }).toLowerCase();
  return daysOfWeek[dayAbbreviation] || dayAbbreviation;
}

export default function TimeSchedule() {
  const dispatch = useDispatch()
  const timeScheduleGridRef = useRef(null)
  const {
    selectedHealthProfessional,
    filledTimesOfTheDay,
    selectedTimesCursor,
    setSelectedTimesCursor,
    health_place_user__user__person,
    calendarCursor, 
    openAvailableScheduleModal,
    setModalInfo,
  } = useBookingAppointmentCursor()

  useEffect(() => {
    const calculateScrollPosition = () => {
      const now = new Date();
      const startOfDay = new Date(now.setHours(0, 0, 0, 0));
      const diffInMilliseconds = new Date() - startOfDay;
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  
      const hourBlockHeight = 72;
      return diffInHours * hourBlockHeight;
    };
  
    if (timeScheduleGridRef.current) {
      const scrollPosition = calculateScrollPosition();
  
      timeScheduleGridRef.current.scrollTop = scrollPosition - (window.innerHeight / 2);
    }
  }, []);



  const isActiveDay = (day) => {
    const selected = calendarCursor.selectedDay;
    return day.getDate() === selected.getDate() &&
           day.getMonth() === selected.getMonth() &&
           day.getFullYear() === selected.getFullYear();
  }

  const getStartOfWeek = (date) => {
    const diff = date.getDate() - date.getDay();
    return new Date(date.setDate(diff));
  }

  const generateWeekDays = () => {
    let startDate = getStartOfWeek(new Date(calendarCursor.selectedDay));
    let weekDays = [];
    for (let i = 0; i < 7; i++) {
      let day = new Date(startDate);
      day.setDate(day.getDate() + i);
      weekDays.push(day);
    }
    return weekDays;
  }

  const weekDays = generateWeekDays();

  const openCreateSchedulesModal = () => {
    setModalInfo({
        open: true,
        title: 'Criar Horário(s)',
        content: <CreateSchedulesModal />
    })
  }

  const openRecurringPatientModal = () => {
    setModalInfo({
        open: true,
        title: 'Paciente Recorrente',
        content: <RecurringPatientModal />
    })
  }

  const handleEmission = () => {
    let date = buildDate(calendarCursor.selectedDay?.getFullYear(), calendarCursor.selectedDay?.getMonth() + 1, calendarCursor.selectedDay?.getDate())
    
    let start__gte = formatDateWithTimezone(date, '00', '00')
    let start__lte = formatDateWithTimezone(date, '23', '59')
    let params = {
      doctor__in: [selectedHealthProfessional],
      classification__in: [{id: "ROUTINE", name: "Primeira Consulta"}, {id: "RETURN", name: "Consulta de Retorno"}],
      start__gte, 
      start__lte 
    }
    console.log(params)
    dispatch(loading())
    postQuickEmission({ params })
      .then(res => {
          dispatch([loaded(), AddAlert('Agenda', 'Agenda impressa com sucesso', AlertType.SUCCESS)])
          const link = document.createElement('a')
          link.href = res.data.base64
          link.download = `agendamentos-${date}.pdf`
          link.click()
      })
      .catch(() => {
          dispatch([loaded(), AddAlert('Agenda', 'Não foi possível gerar a agenda', AlertType.ERROR)])
      })
  }

  return (
      <div>
        <div className='TimeSchedule-Header'>
          <div 
              title='Criar horário(s)' 
              className='TimeSchedule-CreateHour-Btn' 
              onClick={openCreateSchedulesModal}
          >
            <IcoSchedule />
          </div>
          <div 
              title='Paciente recorrente' 
              className='TimeSchedule-CreateHour-Btn' 
              onClick={openRecurringPatientModal}
          >
            <IcoCalendarLoop />
          </div>
          <div 
              title='Imprimir agenda' 
              className='TimeSchedule-CreateHour-Btn' 
              onClick={handleEmission}
          >
            <PrinterIcon />
          </div>
        </div>

        <div className='TimeSchedule-Body'>
        <div className='TimeSchedule-Body-Header'>
          <div className='TimeSchedule-Body-Header-Cursor'>
            <div className='TimeSchedule-Body-Header-Cursor-AllCheckBox'>
              {/*
                filledTimesOfTheDay?.horarios?.length
                ? <NewCheckbox
                  title=''
                  state={[{ "id": "teste", "name": "", "checked": selectedTimesCursor?.length && selectedTimesCursor.every(appointment => appointment.checked) }]} 
                  setState={() => setSelectedTimesCursor(prev => prev.map(cursorAppointment => ({ ...cursorAppointment, checked: !selectedTimesCursor.every(appointment => appointment.checked) })))}
                  className=''
                  defaultHandleCheckbox={false}
                />  
                : null
            */}
            </div>
          </div>

          <div className='TimeSchedule-Body-Header-CalendarRow'>
            {weekDays.map((day, index) => (
              <div key={index} className={`TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek ${isActiveDay(day) ? 'active' : ''}`}>
                <div className='TimeSchedule-Body-Header-CalendarRow-DayOfTheWeek'>
                  <div>{getDayOfWeek(day)}</div>
                  <div>{day.getDate()}</div> 
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className="TimeSchedule-Body-Content" ref={timeScheduleGridRef}>
          <div className='TimeSchedule-Body-CheckBoxGrid' style={{position: 'relative', height: '100%'}}>
            { 
              HOUR_LABELS.map((num, idx) => <div
                  style={{ cursor: 'pointer' }} 
                  onClick={() => null} 
                  key={num} 
                  id={`timeschedule-body-hoursgrid-${num}`}
                >
                </div>)
            }
            <RenderAppointmentsCheckbox />
          </div>
          <div className='TimeSchedule-Body-HourLabels'>
            { 
              HOUR_LABELS.map(num => num != '23:59' ? <div>{num}</div> : <div></div>)
            }
          </div>
          <div className='TimeSchedule-Body-Spliter'>
            { 
              HOUR_LABELS.map(() => <div></div>)
            }
          </div>
          <div className='TimeSchedule-Body-HoursGrid' style={{position: 'relative', height: '100%'}}>
            { 
              HOUR_LABELS.map(num => <div 
                  style={{ cursor: 'pointer' }} 
                  onClick={openAvailableScheduleModal} 
                  key={num} 
                  id={`timeschedule-body-hoursgrid-${num}`}
                >
                  
                </div>)
            }
            <RenderCurrentTimeLine />
            <RenderAppointments />
          </div>
        </div>
      </div>
    </div>
  )
}