import './AttendanceHistory.css'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { IcoEstetoscopio, Paginate, adicionaZero } from '../../../../component/index.js'
import Loading from '../../../../newcomponents/loading/loading.js'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal.js'
import { formatDateWithTimezone, buildDate } from '../../../../utils/convertDate.js'
import { getAttendancesHistory } from '../../http.js'
import AttendanceHistoryFilters from './subcomponents/AttendanceHistoryFilters/AttendanceHistoryFilters.js'
import AttendanceUnit from './subcomponents/AttendanceUnit/AttendanceUnit.js'
import { useApp } from '../../../../layout/App.js'

const AttendanceHistoryContext = React.createContext()

export function useAttendanceHistory() {
	return useContext(AttendanceHistoryContext)
}

export const strpAttendancePeriod = () => {
	const today_date = new Date()

	return {
		year_start: '',
		month_start: '',
		day_start: '',
		year_end: String(today_date.getFullYear()),
		month_end: String(adicionaZero(today_date.getMonth() + 1)),
		day_end: String(adicionaZero(today_date.getDate()))
	}
}

export function parseParams(attendancePeriod, filtersRef) {
	const start__gte = buildDate(attendancePeriod?.year_start, attendancePeriod?.month_start, attendancePeriod?.day_start)
	const start__lte = buildDate(attendancePeriod?.year_end, attendancePeriod?.month_end, attendancePeriod?.day_end)
	
	return {
		name_or_cpf: filtersRef.current.name_or_cpf,
		doctor__id: filtersRef.current.doctor__id,
		decision: filtersRef.current.decision,
		result: filtersRef?.current?.result,
		classification: filtersRef?.current?.classification,
		priority: filtersRef?.current?.priority,
		specialty: filtersRef?.current?.specialty,
		health_insurance: filtersRef?.current?.health_insurance,
		start__gte: formatDateWithTimezone(start__gte, '00', '00'),
		start__lte: formatDateWithTimezone(start__lte, '23', '59'),
		person__birthday__gte: filtersRef?.current?.person__birthday__gte,
		person__birthday__lte: filtersRef?.current?.person__birthday__lte,
		
		offset: filtersRef?.current?.offset,
		limit: 18,
		active: true,
	}
}

export default function AttendanceHistoryPage() {
	const { initFilters } = require('../../../AttendanceManager/data.json')
	const [loading, setLoading] = useState(true)
	const [attendances, setAttendances] = useState([])
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
	const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
	const [attendancePeriod, setAttendancePeriod] = useState(strpAttendancePeriod())
	const filtersRef = useRef(initFilters)
	const searchTimeOut = useRef(null)
	const { currentHealthPlaceUser } = useApp()

	const fetchAttendancesHistory = async params => {
		const builtParams = parseParams(attendancePeriod, filtersRef)
		params = { 
			...builtParams, 
			...params, 
			limit: 18, 
			health_place__id: currentHealthPlaceUser?.health_place?.id
		}

		setLoading(true)
		try {
			let response = await getAttendancesHistory(params)
			setAttendances(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 18),
				pageNumber: params.offset / 18,
			})
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error('fetchAttendancesHistory ~ ', err)
		}
	}

	useEffect(() => {
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(async () => {
				await fetchAttendancesHistory({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}, [attendancePeriod])

	const handleNextPage = e => {
		fetchAttendancesHistory({ offset: e * 18 })
	}

	return (
		<AttendanceHistoryContext.Provider
			value={{
				attendances,
				pagination,
			}}>
			<Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
			<div className='AttendanceHistoryPage-Content'>
				<AttendanceHistoryFilters
					filtersRef={filtersRef}
					attendancePeriod={attendancePeriod}
					setAttendancePeriod={setAttendancePeriod}
					fetchAttendancesHistory={fetchAttendancesHistory}
					setModalProps={setModalProps}
				/>
				<div className='AttendanceHistoryPage-Separator' />
				<div className='AttendanceHistoryPage-Body'>
					{
						loading 
						? <div className='AttendanceHistoryPage-Loading'><Loading /></div>
						: <div>
							{
								attendances?.length
								? <div className='AttendanceHistoryPage-AttendanceUnitWrapper'>
									{attendances.map(attendance => <AttendanceUnit
										attendance={attendance}
										setModalProps={setModalProps}
										fetchAttendancesHistory={fetchAttendancesHistory}
									/>)}
									<Paginate data={pagination} action={handleNextPage} />
								</div>
								: <div className='AttendanceHistoryPage-NotFound'>
									<div>
										<IcoEstetoscopio />
										<p><b>Nenhum atendimento foi encontrado</b></p>
									</div>
								</div>
							}
						</div> 
					}
				</div>
			</div>
		</AttendanceHistoryContext.Provider>
	)
}
