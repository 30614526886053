export function uniqueIDs(selected, combined=[]) {
    if (selected?.id) {
        combined.unshift(selected)
    }

    let uniqueDict = {};
    let uniqueById = [];

    for (let item of combined) {
        if (item?.id && !uniqueDict[item.id]) {
            uniqueDict[item.id] = true;
            uniqueById.push(item);
        }
    }

    return uniqueById
}