import './PersonalDataCRMManagerModal.css'
import '../PersonalDataCRMManager.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType, Button, IcoBin, IcoPencil, Input, List } from '../../../../../../../../component'
import NewSelect from '../../../../../../../../newcomponents/form/select/select'
import NewButton, {
	ButtonModel,
} from '../../../../../../../../newcomponents/button/button'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { useConfiguration } from '../../../../../../configuration'
import { useApp } from '../../../../../../../../layout/App'
import { buildDate } from '../../../../../../../../utils/convertDate'
import { patchCouncil, postCouncil } from '../../../../../../http'
import EditCRMModal from '../EditCRMModal/EditCRMModal'

const strpCouncilData = (data) => {
	return {
		"id": data?.id || null,
		"council": data?.council || "",
		"nu_crm": data?.nu_crm || "",
		"uf_crm": data?.uf_crm || "",
		"person": data?.person || "",
		"registration_date": data?.registration_date || "",
		"specialties": data?.specialties || ""
	}
}

const councilHead = [
	{ colunm: 'h_nu_crm', text: 'Número' },
	{ colunm: 'h_uf_crm', text: 'UF' },
	{ colunm: 'h_actions', text: '', width: '100px' },
]

export default function PersonalDataCRMManagerModal({ initCRMData, councils }) {
	const dispatch = useDispatch()
	const [holdingCRM, setHoldingCRM] = useState(strpCouncilData(initCRMData))
	const [holdingCRMErr, setHoldingCRMErr] = useState({})
	const { listas } = useSelector(state => state.appState)
	const { currentHealthPlaceUser, fetchUserCouncils, loged } = useApp()
	const { setModalProps } = useConfiguration()
	const [secondaryModalProps, setSecondaryModalProps] = useState(MODAL_INIT_PROPS)

	const handleRemove = async (selectedCouncil) => {
		dispatch(loading())
		try {
			await patchCouncil(selectedCouncil?.id, { situation: 'D'})
			dispatch([loaded(), AddAlert(`Remoção de CRM`, `CRM removido com sucesso`, AlertType.SUCCESS)])
			handleClose()
		} catch (err) {
			console.error('handleRemove', err)
		}
		fetchUserCouncils()
	}

	const handleChange = event => {
		setHoldingCRMErr({})
		setHoldingCRM(prev => ({
			...prev,
			[event.target.name]:
			event.target.value[0] === 't'
				? event.target.value
				: event.target.value.replace(/\D/g, ''),
		}))
	}

	const handleClose = () => {
		setModalProps(MODAL_INIT_PROPS)
		setHoldingCRM(initCRMData)
	}

	const handleSelect = event => {
		setHoldingCRMErr({})
		setHoldingCRM(prev => ({ ...prev, uf_crm: event.target.selected }))
	}

	const handleSaveCRM = async () => {
    let errors = {}
		if (!holdingCRM.nu_crm) errors['nu_crm'] = 'Número obrigatório'
		if (!holdingCRM.uf_crm) errors['uf_crm'] = 'UF obrigatório'
		if (Object.keys(errors).length) return setHoldingCRMErr(errors)
		let currentDate = new Date()
		let payload = {
			...holdingCRM,
			'person': currentHealthPlaceUser?.user?.person?.id,
			'registration_date': buildDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
		}
		dispatch(loading())
		try {
			if (holdingCRM?.id) {
				await patchCouncil(holdingCRM?.id, payload)
			} else {
				await postCouncil(payload)
			}

			await fetchUserCouncils()
			dispatch([loaded(), AddAlert(`Cadastro de CRM`, `CRM salvo com sucesso`, AlertType.SUCCESS)])
			await loged()
			setModalProps(MODAL_INIT_PROPS)
		} catch (err) {
			console.error(err)
			dispatch([
				loaded(),
				AddAlert(
					`Cadastro de CRM`,
					`Falha ao salvar CRM, tente novamente!`,
					AlertType.ERROR
				),
			])
		}
	}

	return (
		<div className='SaveCRMModal'>
			<Modal
					dismissText='Fechar'
					dismissFn={() => setSecondaryModalProps(MODAL_INIT_PROPS)}
					{...secondaryModalProps}
				/>

			<List
				head={councilHead}
				data={councils.map(instance => ({ ...instance }))}
				noData={''}
				maxHeight='200px'
				listCustom={council => {
					const custom = council
					custom['h_nu_crm'] = council.nu_crm
					custom['h_uf_crm'] = council.uf_crm
					custom['h_actions'] = (
					 	<div className='SaveCRMModal-Actions'>
					 		<Button
					 			color='secondary'
					 			type='btn circle'
					 			action={() => {
									setSecondaryModalProps(prev => ({
					 					...prev,
					 					open: true,
					 					title: 'Conselho profissional',
					 					content: (<EditCRMModal initCRMData={council} setSecondaryModal={setSecondaryModalProps} />),
					 					dismissText: '',
					 				}))
					 			}}
					 			title='Editar'>
					 			<IcoPencil />
					 		</Button>
					 		<Button
					 			color='danger'
					 			type='btn circle'
					 			action={() => handleRemove(council)}
					 			title='Remover'>
					 			<IcoBin />
					 		</Button>
					 	</div>
					 )
					return custom
				}}
			/>
			<b className='SaveCRMModal-Subtitle'>Adicionar CRM</b>
			<div className='SaveCRMModal-CRMCol'>
				<Input
					label='Número'
					name='nu_crm'
					action={handleChange}
					value={holdingCRM.nu_crm}
					maxLength='8'
					required={{ erro: holdingCRMErr, message: holdingCRMErr.nu_crm }}
					placeholder='ex: 123456'
				/>
				<NewSelect
					options={listas.estados}
					canBeEmpty={false}
					label='UF'
					optionRefKey='sigla'
					optionStrKey='nome'
					selected={holdingCRM.uf_crm}
					onSelect={handleSelect}
					error={holdingCRMErr?.uf_crm}
				/>
			</div>
			<div className='SaveCRMModal-SaveBtn'>
				<NewButton
					label='Fechar'
					onClick={handleClose}
					model={ButtonModel.SECONDARY}
				/>
				<NewButton
					label='Salvar CRM'
					onClick={handleSaveCRM}
				/>
			</div>
		</div>
	)
}
