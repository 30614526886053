import React from 'react'
import { useDispatch } from 'react-redux'
import { validarDados } from '../../../../../../../usuario/redux/UsuarioActions'
import ActionForm from '../../../../../../../../component/form/actions/actions'
import { IcoSearch, Input, MaskItemCep } from '../../../../../../../../component'


const addressDefault = {
  zip: '',
  address: '',
  number: '',
  complement: '',
  district: '',
  city: {
    state: '',
    name: ''
  }
}

export default function FormAddress({address = addressDefault, changeDados, searchCep, validacao = {}}) {
  const dispatch = useDispatch()

  return (
    <div className='box-registrar-conteudo'>
      <div className='row'>
          <div className='col-12 col-sm-5'>
            <Input
              label='CEP'
              name='zip'
              action={e => changeDados(e)}
              actionBlur={(e, v)=>  dispatch(validarDados(e, v))}
              value={address.zip ? MaskItemCep(address.zip) : ''}
              maxLength='9'
              required={{
                pattern: 'zip',
                erro: validacao,
                message: validacao.zip === 'invalido' ? 'Preencha CEP valido' : 'Campo obrigatório'
              }}
            >
              <ActionForm
                action={_ => searchCep(address.zip)}
                title='Pesquisar'
              >
                <IcoSearch />
              </ActionForm>
            </Input>
          </div>
          <div className='col-12 col-sm-7' />
          <div className='col-4 col-sm-3'>
            <Input
              label='UF'
              name='state'
              disabled={true}
              action={e => changeDados(e)}
              value={address.city?.state ? address.city?.state : ''}
              required={{
                erro: validacao,
                message: 'Obrigatório'
              }}
            />
          </div>
          <div className='col-8 col-sm-5'>
            <Input
              label='Cidade'
              name='city'
              disabled={true}
              action={e => changeDados(e)}
              value={address.city?.name ? address.city?.name : ''}
              required={{
                erro: validacao,
                message: 'Obrigatório'
              }}
            />
          </div>
          <div className='col-12 col-sm-5'>
            <Input
              label='Bairro'
              name='district'
              action={e => changeDados(e)}
              actionBlur={(e, v)=>  dispatch(validarDados(e, v))}
              value={address.district ? address.district : ''}
              required={{
                erro: validacao,
                message: 'Campo obrigatório'
              }}
            />
          </div>
          <div className='col-12'>
            <Input
              label='Endereço'
              name='address'
              action={e => changeDados(e)}
              actionBlur={(e, v)=>  dispatch(validarDados(e, v))}
              value={address.address  ? address.address : ''}
              required={{
                erro: validacao,
                message: 'Campo obrigatório'
              }}
            />
          </div>
          <div className='col-12 col-sm-4'>
            <Input
              label='Número'
              name='number'
              action={e => changeDados(e)}
              actionBlur={(e, v)=>  dispatch(validarDados(e, v))}
              value={address.number ? address.number : ''}
              required={{
                erro: validacao,
                message: 'Campo obrigatório'
              }}
            />
          </div>
          <div className='col-12'>
            <Input
              label='Complemento'
              name='complement'
              action={e => changeDados(e)}
              value={address.complement ? address.complement : ''}
            />
          </div>
        </div>
    </div>
  )
}