import { combineReducers } from 'redux'
import alerts, { alertsInitState } from './component/alert/alertsRedux'
import appState, { appInitState } from './layout/redux/AppReducer'
import usuarioState, { userInitState } from './pages/usuario/redux/UsuarioReducer'

const initStates = {
	alerts: alertsInitState,
	appState: appInitState,
	usuarioState: userInitState,
}

const allReducers = combineReducers({ alerts, appState, usuarioState })

export const rootReducer = (state, action) => {
	if (action.type === 'RESET_ALL_STATES') {
		state = initStates
	}
	return allReducers(state, action)
}

export default rootReducer
