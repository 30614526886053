import './consultation-header.css'
import React, { useState } from "react";
import { AddAlert, AlertType, IcoEstetoscopio, MaskDataHora, idade } from '../../../../component'
import VideoCallScreen from '../../../../component/video/VideoCallScreen';
import { useAttendance } from '../../consultation';
import SendIcon from '../../../../component/icon/send';
import CallIcon from '../../../../component/icon/telephone';
import { postVideoCallToken } from '../../http';
import { useDispatch } from 'react-redux';
import Button, { ButtonModel } from '../../../../newcomponents/button/button';
import { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal';
import { PatientIcon } from '../../../../component/icon/patient';
import { HospitalIcon } from '../../../../component/icon/hospital';
import { userRoles } from '../../../../utils/userRoles';

const ShareVideoCallTokenModal = ({ url }) => {
    const dispatch = useDispatch()
    const { setModalInfo } = useAttendance()

    const copyCallLink = () => {
        navigator.clipboard.writeText(url)
        dispatch(AddAlert('Link de chamada', `Link de chamada copiado com sucesso, agora compartilhe com o paciente.`, AlertType.SUCCESS))
        setModalInfo(MODAL_INIT_PROPS)
    }

    return <div className='ShareVideoCallTokenModal'>
        <p>Clique em <b>'Compartilhar link'</b> para copiar o endereço da chamada. Em seguida, você pode facilmente compartilhar este link com seu paciente.</p>
        <p>*Para garantir a melhor qualidade durante a consulta, recomendamos o uso de <b>laptops ou computadores</b>.</p>
        <div className='ShareVideoCallTokenModal-BtnBox'>
            <Button
                label='Compartilhar link de chamada'
                onClick={copyCallLink}
            />
            <Button
                label='Fechar'
                model={ButtonModel.SECONDARY}
                onClick={() => setModalInfo(MODAL_INIT_PROPS)}
            />
        </div>
    </div>
}

export default function ConsultationHeader() {
    const { attendance, setModalInfo } = useAttendance()
    const { person } = attendance
    const [videoCallToken, setVideoCallToken] = useState(null)

    const getAge = () => {
        if (person?.birthday) {
            const age = idade(person.birthday)
            return (age.anos ? `${age.anos} ${age.anos <= 1 ? 'ano' : 'anos'}` : age.mes ? `${age.mes} ${age.mes <= 1 ? 'mes' : 'meses'}` : '0')
        } else {
            return '-'
        }
    }

    const handleCall = async (params={}) => {
        await postVideoCallToken({ ...params, attendance: attendance.id })
            .then((res) => {
                if (params['mode'] === 'visitant') {
                    setModalInfo(prev => ({
                        ...prev,
                        open: true,
                        title: 'Compartilhar chamada com paciente',
                        content: <ShareVideoCallTokenModal url={res.data.url} />
                    }))
                } else {
                    setVideoCallToken(res.data.token)
                }
            })
            .catch(err => console.log(err))
    }

    const attendance__start = MaskDataHora(attendance?.start, 'DD/MMM/AAAA', '-')

    return <div>
        <div className='Consultation-Header'>
            <div className='Consultation-Header-SlotBox'>
                <div className='Consultation-Header-SlotBox-Slot'>
                    <div className='Consultation-Header-SlotBox-Slot-IconBox'>
                        <IcoEstetoscopio style={{ fill: '#fff', fontSize: '21px' }} />
                    </div>
                    <div className='Consultation-Header-SlotBox-Slot-Info'>
                        <b>{ attendance?.protocol || '-' }</b><br/>
                        <span>{attendance__start?.data} - {attendance__start?.hora?.slice(0, 5)}</span>
                    </div>
                </div>
                <div className='Consultation-Header-SlotBox-Slot'>
                    <div className='Consultation-Header-SlotBox-Slot-IconBox'>
                        <PatientIcon style={{ fill: '#fff', width: '21px' }} />
                    </div>
                    <div className='Consultation-Header-SlotBox-Slot-Info'>
                        <b>{ attendance?.person?.name || '-' }</b><br/>
                        <span>{getAge()} | {person?.sex === 'F' ? 'Feminino' : 'Masculino'} {person.medical_record_number ? `| Prontuário ${person.medical_record_number}`: ''}</span>
                    </div>
                </div>
                <div className='Consultation-Header-SlotBox-Slot'>
                    <div className='Consultation-Header-SlotBox-Slot-IconBox'>
                        <IcoEstetoscopio style={{ fill: '#fff', fontSize: '21px' }} />
                    </div>
                    <div className='Consultation-Header-SlotBox-Slot-Info'>
                        <b>{ attendance?.doctor?.name || attendance?.extra_kwargs?.external_doctor_name || 'Profissional' }</b><br/>
                        {
                            attendance?.extra_kwargs?.external_doctor_name
                            ? <span>Médico</span>
                            : <span>{ userRoles[attendance?.doctor?.user?.health_place_users?.[0]?.profile]?.title || '-' }</span>
                        }
                    </div>
                </div>
                {
                    attendance?.health_place?.name
                    ? <div className='Consultation-Header-SlotBox-Slot'>
                        <div className='Consultation-Header-SlotBox-Slot-IconBox'>
                            <HospitalIcon style={{ fill: '#fff', width: '21px' }} />
                        </div>
                        <div className='Consultation-Header-SlotBox-Slot-Info'>
                            <b>{ attendance?.health_place?.name || '-' }</b><br/>
                            <span>Instituição</span>
                        </div>
                    </div>
                    : null
                }
            </div>
            <div className='Consultation-Header-ActionBox'>
                <div onClick={handleCall}>
                    <CallIcon className='Consultation-Header-CallIcon' />
                </div>
                <div onClick={() => handleCall({ mode: 'visitant' })}>
                    <SendIcon className='Consultation-Header-SendIcon' />
                </div>
            </div>
        </div>

        {
          videoCallToken
            ? <VideoCallScreen
                token={videoCallToken}
                setToken={setVideoCallToken}
            />
            : null
        }
    </div>
}