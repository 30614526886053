export const USUARIO_VALIDACAO = 'USUARIO_VALIDACAO'
export const USUARIO_CARREGAR = 'USUARIO_CARREGAR'
export const SET_USUARIO_VALIDACAO = 'SET_USUARIO_VALIDACAO'
export const setValidacao = () => ({
  type: SET_USUARIO_VALIDACAO,
})



export const USUARIO_VALIDACAO_CAMPO = 'USUARIO_VALIDACAO_CAMPO'
export const validacaoCampo = (e) => ({
  type: USUARIO_VALIDACAO_CAMPO,
  payload: e
})

export const validarDados = (e, v) => {
  return dispatch => {
      dispatch(validacaoCampo(v))
  }
}