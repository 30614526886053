import './UnavailableHealthPlaceMenu.css'
import React, { useState } from "react";
import { IcoBin, MaskDataHora } from '../../../../../../../../../../../../component';
import IcoQuickly from '../../../../../../../../../../../../component/icon/quickly';
import { useBookingAppointmentCursor } from '../../../../../../BookingAppointmentCursor';

export default function UnavailableHealthPlaceMenu({ appointment={} }) {
    const { setModalInfo } = useBookingAppointmentCursor()
    const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
    const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');

    return <div className='UnavailableHealthPlaceMenu'>
      <div>
        <div>
          <strong>Agendamento associado à outra Instituição</strong>
        </div>
        <div className='UnavailableHealthPlaceMenu-HourTime'>
          <IcoQuickly style={{ fontSize: '16px' }} /> 
          <span style={{ fontSize: '12px' }}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
        </div>
      </div>
    </div>
  }