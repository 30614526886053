import './SelectPatient.css'
import React, { useRef, useState } from "react";
import { Input, MaskTelefone } from '../form/form';
import Button, { ButtonModel } from '../../newcomponents/button/button';
import InfoLine from '../info-line/info-line';
import { convertDate } from '../../utils/convertDate';
import Portal from '../potal/portal';
import AlertRowText, { AlertRowTextType } from '../alertRowText/alertRowText';
import useOutsideClick from '../useOusideClick/useoutsideclick';
import { getHealthPlacePatients } from '../../pages/pacientes/http';
import { PatientIcon } from '../icon/patient';

export default function SelectPatient({ label=<strong>Pesquisar paciente</strong>, selectedPatient=null, inputErr=false, handleSelectPatient=() => null, fixedFilters={} }) {
    const ref = useRef()
    const searchTimeOut = useRef(null)
    const [patients, setPatients] = useState([])
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [dropdownStyles, setDropdownStyles] = useState({})
    const [searchParam, setSearchParam] = useState('')

    useOutsideClick(ref, e => {
        if (!e && isDropdownOpen) setIsDropdownOpen(false)
    })

    const handleOpenDropdown = (event) => {
        const inputRect = event.target.getBoundingClientRect()
        setDropdownStyles({
            left: inputRect.left + window.scrollX,
            top: inputRect.bottom + window.scrollY,
            width: inputRect.width,
            position: 'absolute'
        });
        setIsDropdownOpen(true);
    }

    const selectPatient = (patient) => {
        handleSelectPatient(patient)
        setIsDropdownOpen(false)
        setSearchParam('')
    }

    const fetchPatients = async (params={}) => {
        let response = await getHealthPlacePatients({ ...params, ...fixedFilters })
        setPatients(response.data.results)
    }

    const handleSearchPatient = async (event) => {
        setSearchParam(event.target.value)
        searchTimeOut.current && clearTimeout(searchTimeOut.current)
        searchTimeOut.current = setTimeout(async () => {
            let params = { name_or_cpf: event.target.value }
            await fetchPatients(params)
            handleOpenDropdown(event)
        }, 300, event.target.name)
    }

    return <div>
        <div>
            <div>
                {label}
                <Input 
                    name='patient_name_or_cpf'
                    placeholder='Nome | CPF | RG' 
                    action={handleSearchPatient}
                    value={searchParam}
                    errors={{ error: { patient_name_or_cpf: inputErr }, message: inputErr }}
                />
                {
                    isDropdownOpen && (
                        <Portal  name='patient-dropdown'>
                            <div ref={ref} style={dropdownStyles} className='SelectPatient-DropdownBox'>
                                <div className='SelectPatient-DropdownBox-SelectOptions'>
                                    {
                                        patients.map(patient => <div key={patient.id} onClick={() => selectPatient(patient)}>
                                            {patient.name}{ (patient?.cpf || patient?.rg) ? ` - ${patient?.cpf || patient?.rg}` : null }
                                        </div>)
                                    }
                                </div>
                                
                            </div>
                        </Portal>
                    )
                }
            </div>
            {
                !selectedPatient?.id
                ? <div className='SelectPatient-NotFound'>
                    <div>
                        <PatientIcon />
                        <p>Nenhum paciente foi selecionado</p>
                    </div>
                </div>
                : <div className='SelectPatient-SelectedPatient-Wrapper'>
                    <div className='SelectPatient-SaluthoSpan'><strong>Salutho</strong></div>
                    <div className='SelectPatient-SelectedPatient'>
                        <InfoLine 
                            label='Nome do paciente'
                            value={selectedPatient?.name}
                        />
                        <div className='SelectPatient-2Col'>
                            <InfoLine 
                                label='E-mail'
                                value={selectedPatient?.user?.email || '-'}
                            />
                            <InfoLine 
                                label='Prontuário'
                                value={selectedPatient?.medical_record_number || '-'}
                            />
                            <InfoLine
                                label='Documento'
                                value={`${selectedPatient?.document_type} ${selectedPatient?.cpf || selectedPatient?.rg || '-'}`}
                            />
                            <InfoLine 
                                label='Data de nascimento'
                                value={selectedPatient?.birthday ? convertDate(selectedPatient.birthday, '-', '/') : '-'}
                            />
                            <InfoLine 
                                label='Telefone'
                                value={selectedPatient?.contact_phone ? MaskTelefone(selectedPatient.contact_phone) : '-'}
                            />
                            <InfoLine
                                label='Gênero'
                                value={selectedPatient?.sex === 'F' ? 'Feminino' : 'Masculino'}
                            />
                        </div>
                        <div className='SelectPatient-Btn-FullWidth'>
                            <Button 
                                label='Remover'
                                model={ButtonModel.SECONDARY}
                                onClick={() => handleSelectPatient({})}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}