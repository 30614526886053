import React from "react";
import SelectPatient from '../../../../../../../../../../component/SelectPatient/SelectPatient';
import NewButton from '../../../../../../../../../../newcomponents/button/button';

const PageOne = ({ recurringAppointment, recurringAppointmentErr, setRecurringAppointment, setRecurringAppointmentErr, setCurrentPage }) => {
    const handleSelectPatient = (patient) => {
        setRecurringAppointment(prev => ({ ...prev, "person": patient }))
    }

    return (
        <div>
            <SelectPatient
                label={'Pesquisar paciente'}
                selectedPatient={recurringAppointment.person} 
                handleSelectPatient={handleSelectPatient}
                inputErr={recurringAppointmentErr?.person}
            />
            <div className='RecurringPatientModal-BtnBox'>
                <NewButton 
                    label='Prosseguir'
                    disabled={!recurringAppointment?.person?.id}
                    onClick={() => setCurrentPage('2')}
                />
            </div>
        </div>
    );
};

export default PageOne;
