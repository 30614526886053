import './ReportsPage.css'
import React, { useEffect, useState } from 'react'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal'
import ReportsPageAttendanceFilters from './subcomponents/ReportsPageAttendanceFilters/ReportsPageAttendanceFilters'
import Loading from '../../../../newcomponents/loading/loading'
import { deleteReportsRoutine, getReportsRoutine } from '../../http'
import { IcoBin, MaskDataHora } from '../../../../component'
import NewEditRoutineModal from './subcomponents/NewEditRoutine/NewEditRoutine'
import { EditingIcon } from '../../../../component/icon/editing'
import { RoutineReportsIcon } from '../../../../component/icon/routineReports'

const defaultOptions = require('../../../../component/form/select/options.json')

export const INIT_FILTER_REPORTS = {
	"doctor__in": [],
	"specialty__in": [],
	"health_insurance__in": [],
	"person__address__city__in": [],
	"classification__in": [],
	"decision__in": [],
	"priority__in": [],
	"result__in": [],
	"related_diseases_and_problems__code__in": [],
	"year_start": "", 
	"month_start": "", 
	"day_start": "", 
	"year_end": "", 
	"month_end": "", 
	"day_end": ""
}

export function ReportsPage() {
	const [filters, setFilters] = useState(INIT_FILTER_REPORTS)
	const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
	const [loading, setLoading] = useState(false)
	const [routines, setRoutines] = useState([])

	useEffect(() => {
		fetchRoutines()
	}, [])

	const fetchRoutines = async () => {
		setLoading(true)
		try {
			const res = await getReportsRoutine()
			setRoutines(res.data.results)
		} catch (err) {
			setRoutines([])
			console.error('ReportsPage ~ fetchRoutines ~ ', err)
		}
		setLoading(false)
	}

	const handleDeleteRoutine = async (routineID) => {
		await deleteReportsRoutine(routineID)
			.then(() => fetchRoutines())
	}

  	return (
    <div className='ReportsPage'>
      	<Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
			<div className='ReportsPage-Content'>
				<ReportsPageAttendanceFilters 
					fetchRoutines={fetchRoutines} 
					setModalInfo={setModalInfo}
					filters={filters}
					setFilters={setFilters}
				/>
				<div className='ReportsPage-Separator' />
				<div className='ReportsPage-Body'>
					{
						loading 
						? <div className='ReportsPage-Loading'><Loading /></div>
						: <div>
							{
								routines?.length
								? <div className='ReportsPage-ReportUnitWrapper'>
									{
										routines.map((routine, idx) => <div key={idx} className='ReportsPage-ReportUnitWrapper-Unit'>
											<div className='ReportsPage-ReportUnitWrapper-Unit-FirstBox'>
												<b className='ReportsPage-ReportUnitWrapper-Unit-Subtitle'>{routine?.description || '-'}</b>
												<span>
													{defaultOptions.PERIODICITY_CHOICES.find(({ id }) => id === routine?.reports_periodicity)?.name || '-' } | {routine?.reports_email || '-'}
												</span>
												<span>{routine?.last_time === '1950-01-01' ? 'Primeiro envio agendado' : `Último envio: ${MaskDataHora(routine?.last_time)?.data}`}</span>
											</div>
											<div className='AttendanceUnit-ActionRow'>
												<div 
													className='AttendanceUnit-ActionRow-Btn' 
													onClick={() => setModalInfo({
														open: true,
														title: 'Salvar Rotina',
														content: <NewEditRoutineModal 
															initRoutine={routine}
															fetchRoutines={fetchRoutines}
															setModalInfo={setModalInfo}
															setFilters={setFilters}
														/>
													})}
												>
													<EditingIcon /> <b>Editar</b>
												</div>
												<div 
													className='AttendanceUnit-ActionRow-Btn' 
													onClick={() => handleDeleteRoutine(routine?.id)}
												>
													<IcoBin /> <b>Remover</b>
												</div>
											</div>
										</div>)
									}
								</div>
								: <div className='ReportsPage-NotFound'>
									<div>
										<RoutineReportsIcon />
										<p><b>Nenhuma rotina de relatório cadastrada</b></p>
									</div>

								</div>
							}
						</div> 
					}
				</div>
			</div>
    </div>
    )
}
