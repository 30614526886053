import { perfilUser } from '../../../utils/userRoles'
import {
  USUARIO_CARREGAR,
  USUARIO_VALIDACAO,
  USUARIO_VALIDACAO_CAMPO,
  SET_USUARIO_VALIDACAO,
} from './UsuarioActions'


const user = JSON.parse( localStorage.user ? localStorage.user : '{}' )
const perfil = perfilUser(user)
const userCPF = user.person?.cpf ? user.person?.cpf : ''

const initialUser = {
  nome: '',
  email: '',
  cpf: '',
  dataNascimento: '',
  telefone: '',
  genero: {},
  estadoCivil: {},
  qtdFilhos: 0,
  chaveCertificadoDigital: '',

  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',

  valorConsulta: '',
  tempoConsulta: '',
  crms: [],
  profissao: {},

  senha1: '',
  senha2: ''
}

export const userInitState = {
  registrar: { ...initialUser, perfil, id: user.id, email: user.email, cpf: userCPF},
  validacao: {},
  profissao: [],
  disponibilidade: false,
  listaGenero: [
    { id: 'M', name: 'Masculino' },
    { id: 'F', name: 'Feminino' }
  ],
  listaEstadoCivil: [
    { id: 'M', name: 'Casado (a) / União Estável' },
    { id: 'D', name: 'Separado (a) / Divorciado (a)'},
    { id: 'S', name: 'Solteiro (a)' },
    { id: 'W', name: 'Viúvo (a)' },
    { id: 'L', name: 'Moro junto com o meu parceiro (a)'}
  ]
}

export default function UsuarioReducer (state = userInitState, { type, payload }) {
  switch (type) {
    case USUARIO_CARREGAR:
      return { ...state, usuario: {...initialUser, ...state.usuario, ...payload } }
    case USUARIO_VALIDACAO:
      return { ...state, validacao: payload }
    case USUARIO_VALIDACAO_CAMPO:
      return { ...state, validacao: {...state.validacao, [payload.campo]: payload.mensagem} }
    case SET_USUARIO_VALIDACAO:
      return { ...state, validacao: {} }

    default:
      return state
  }
}
