import './HealthPlaceLegalDataModal.css'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../../../../component/alert/actions'
import { Input, MaskCNPJ, MaskTelefone, Select } from '../../../../../../../../component'
import NewButton from '../../../../../../../../newcomponents/button/button'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'

const strpHealthPlace = (health_place) => {
	return {
		name: health_place?.name || '',
		cnpj: health_place?.cnpj || '',
		type: health_place.type || '',
		contact_whatsapp: health_place?.contact_whatsapp || ''
	}
}

const parseHealthPlace = (health_place) => {
	return {
		name: health_place?.name || '',
		cnpj: health_place?.cnpj?.replace(/\D/g, '') || '',
		type: health_place.type || '',
		contact_whatsapp: health_place?.contact_whatsapp?.replace(/\D/g, '') || '',
	}
}

export default function HealthPlaceLegalDataModal() {
	const dispatch = useDispatch()
	const { institution, saveHealthPlace, setModalProps } = useConfiguration()
	const [holdingHealthPlace, setHoldingHealthPlace] = useState(strpHealthPlace(institution))
	const [validacao, setValidacao] = useState({ cnpf: false, contact_whatsapp: false, name: false, type: false })
	const { listas } = useSelector((state) => state.appState)

	const handleChange = (event) => {
		setHoldingHealthPlace(prev => ({
			...prev,
			[event.target.name]: event.target.name === 'type' ? event.target.value.id : event.target.value,
		}))
	}

	const handleValidate = (event, v) => {
		if (event) {
			setValidacao({ ...validacao, [v.campo]: v.mensagem })
		}
	}

	const handleSave = () => {
		const validation = Object.values(validacao).some((valor) => valor !== false && valor !== undefined
		)
		if (validation) {
			dispatch(AddAlert('Erro na validação', 'Campos obrigatórios vazios ou valor inválido', AlertType.ERROR))
			return
		}
		saveHealthPlace(institution?.id, parseHealthPlace(institution))
		setModalProps(MODAL_INIT_PROPS)
	}

	return (
		<div className='HealthPlaceLegalDataModal-Container'>
			<div className='HealthPlaceLegalDataModal-PersonalInfo'>
				<div className='HealthPlaceLegalDataModal-Subtitle-Header'>
					<div className='HealthPlaceLegalDataModal-Subtitle-Header-Icon HealthPlaceLegalDataModal-Subtitle-Header-Icon-Blue'>D</div>
					<div className='HealthPlaceLegalDataModal-Subtitle-Header-Title'>Dados Jurídicos</div>
				</div>
			</div>
			<div>
				<div className='HealthPlaceLegalDataModal-Input'>
					<span><b className='HealthPlaceLegalDataModal-Asterisk'>*</b> Nome</span>	
					<Input
						name='name'
						action={handleChange}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={holdingHealthPlace.name}
						required={{ erro: validacao, message: 'Campo obrigatório' }}
					/>
				</div>
				<div className='HealthPlaceLegalDataModal-Input'>
					<span><b className='HealthPlaceLegalDataModal-Asterisk'>*</b> CNPJ</span>	
					<Input
						name='cnpj'
						action={handleChange}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={MaskCNPJ(holdingHealthPlace.cnpj)}
						maxLength='18'
						required={{
							pattern: 'cnpj',
							erro: validacao,
							message: validacao.cnpj === 'invalido' ? 'Preencha CNPJ valido' : 'Campo obrigatório',
						}}
					/>
				</div>
				<div className='HealthPlaceLegalDataModal-Input'>
					<span><b className='HealthPlaceLegalDataModal-Asterisk'>*</b> Tipo</span>
					<Select
						name='type'
						action={handleChange}
						actionClose={(e, v) => handleValidate(e, v)}
						options={listas.tipos}
						selected={holdingHealthPlace.type || {}}
						required={{ erro: validacao, message: 'Campo obrigatório' }}
					/>
				</div>
				<div className='HealthPlaceLegalDataModal-Input'>
					<span><b className='HealthPlaceLegalDataModal-Asterisk'>*</b> Contato (WhatsApp)</span>
					<Input
						name='contact_whatsapp'
						action={handleChange}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={MaskTelefone(holdingHealthPlace.contact_whatsapp)}
						maxLength='15'
						required={{ erro: validacao, message: 'Campo obrigatório' }}
					/>
				</div>
			</div>
			<div className='HealthPlaceLegalDataModal-SaveBtn'>
				<NewButton
					onClick={handleSave}
					label='Salvar'
				/>
			</div>
		</div>
	)
}
