import './WaitingRoom.css'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { IcoEstetoscopio, Paginate, adicionaZero } from '../../../../component/index.js'
import Loading from '../../../../newcomponents/loading/loading.js'
import Modal, { MODAL_INIT_PROPS } from '../../../../newcomponents/modal/modal.js'
import { formatDateWithTimezone, buildDate } from '../../../../utils/convertDate.js'
import WaitingRoomAttendancesFilters from './subcomponents/WaitingRoomAttendancesFilters/WaitingRoomAttendancesFilters.js'
import AttendanceUnit from '../AttendanceHistory/subcomponents/AttendanceUnit/AttendanceUnit.js'
import { getAttendancesHistory } from '../../http.js'
import { useApp } from '../../../../layout/App.js'


const WaitingRoomContext = React.createContext()

export function useWaitingRoom() {
	return useContext(WaitingRoomContext)
}

export function parseParams(filtersRef) {
	let today_date = new Date()
	today_date = buildDate(String(today_date.getFullYear()), String(adicionaZero(today_date.getMonth() + 1)), String(adicionaZero(today_date.getDate())))
	
	return {
		name_or_cpf: filtersRef.current.name_or_cpf,
		doctor__id: filtersRef.current.doctor__id,
		decision: filtersRef.current.decision,
		result: filtersRef?.current?.result,
		classification: filtersRef?.current?.classification,
		priority: filtersRef?.current?.priority,
		specialty: filtersRef?.current?.specialty,
		health_insurance: filtersRef?.current?.health_insurance,
		start__gte: formatDateWithTimezone(today_date, '00', '00'),
		start__lte: formatDateWithTimezone(today_date, '23', '59'),
		person__birthday__gte: filtersRef?.current?.person__birthday__gte,
		person__birthday__lte: filtersRef?.current?.person__birthday__lte,
		
		offset: filtersRef?.current?.offset,
		limit: 18,
		active: true,
	}
}

export default function WaitingRoom() {
	const { initFilters } = require('../../../AttendanceManager/data.json')
	const [loading, setLoading] = useState(true)
	const [attendances, setAttendances] = useState([])
	const [pagination, setPagination] = useState({ pageNumber: 0, totalPages: 1, totalElements: 0 })
	const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
	const filtersRef = useRef(initFilters)
	const searchTimeOut = useRef(null)
	const { currentHealthPlaceUser } = useApp()

	const fetchAttendancesHistory = async params => {
		const builtParams = parseParams(filtersRef)
		params = { 
			...builtParams, 
			...params, 
			limit: 18, 
			has_priority: true, 
			decision__in: "SCHEDULE%2COPEN%2CWAIT%2CGO_PLACE%2CCHECK_IN",
			health_place__id: currentHealthPlaceUser?.health_place?.id
		}

		setLoading(true)
		try {
			let response = await getAttendancesHistory(params)
			setAttendances(response.data.results)
			setPagination({
				totalElements: response.data.count,
				totalPages: Math.ceil(response.data.count / 18),
				pageNumber: params.offset / 18,
			})
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.error('fetchAttendancesHistory ~ ', err)
		}
	}

	useEffect(() => {
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(async () => {
				await fetchAttendancesHistory({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}, [])

	const handleNextPage = e => {
		fetchAttendancesHistory({ offset: e * 18 })
	}

	return (
		<WaitingRoomContext.Provider
			value={{
				attendances,
				pagination,
			}}>
			<Modal {...modalProps} dismissFn={() => setModalProps(MODAL_INIT_PROPS)} />
			<div className='WaitingRoom-Content'>
				<WaitingRoomAttendancesFilters
					filtersRef={filtersRef}
					fetchAttendancesHistory={fetchAttendancesHistory}
					setModalProps={setModalProps}
				/>
				<div className='WaitingRoom-Separator' />
				<div className='WaitingRoom-Body'>
					{
						loading 
						? <div className='WaitingRoom-Loading'><Loading /></div>
						: <div>
							{
								attendances?.length
								? <div className='WaitingRoom-AttendanceUnitWrapper'>
									{attendances.map(attendance => <AttendanceUnit
										attendance={attendance}
										setModalProps={setModalProps}
										fetchAttendancesHistory={fetchAttendancesHistory}
									/>)}
									<Paginate data={pagination} action={handleNextPage} />
								</div>
								: <div className='WaitingRoom-NotFound'>
									<div>
										<IcoEstetoscopio />
										<p><b>Nenhum(a) paciente aguardando atendimento</b></p>
									</div>
								</div>
							}
						</div> 
					}
				</div>
			</div>
		</WaitingRoomContext.Provider>
	)
}
