import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './PersonalDataLegalInformationModal.css'
import { AddAlert, AlertType, Input } from '../../../../../../../../component'
import NewSelect from '../../../../../../../../newcomponents/form/select/select'
import api from '../../../../../../../../helpers/api'
import { isValidCPF } from '../../../../../../../../validators'
import { MaskCpf, MaskTelefone } from '../../../../../../../../component'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { getYearsStart1900 } from '../../../../../../../../utils/getYearsStart1900'
import { useApp } from '../../../../../../../../layout/App'
import isValidDateExcludingFutureDates from '../../../../../../../../validators/isValidDateExcludingFutureDates'

class PersonForm {
	constructor(data) {
		let words = data.name.trim().split(' ')
		for (let i = 0; i < words.length; i++) {
			if (words[i])
				words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase()
		}

		this.name = words.join(' ').trim()
		this.cpf = data.cpf.replace(/\D/g, '')
		this.birthday = `${data?.birthday_year}-${data?.birthday_month}-${data?.birthday_day}`
		this.sex = data.sex
		this.gender = data.gender
		this.contact_phone = data.contact_phone.replace(/\D/g, '')
	}

	getErrors() {
		let errors = {}
		Object.entries(this).map(([key, value]) => {
			switch (true) {
				case key === 'contact_phone' && !([10, 11].includes(value.length)):
					errors['contact_phone'] = 'Contato inválido'
					break
				case key === 'birthday' && !isValidDateExcludingFutureDates(value):
					errors['birthday_day'] = 'Data inválida'
					errors['birthday_month'] = true
					errors['birthday_year'] = true
					break
				case key === 'cpf' && !isValidCPF(value):
					errors['cpf'] = 'CPF inválido'
					break
				case key === 'gender':
					break
				default:
					if (!value) errors[key] = true
			}
			return null
		})
		return errors
	}
}

export default function PersonalDataLegalInformationModal() {
	const dispatch = useDispatch()
	const defaultOptions = require('../../../../../../../../component/form/select/options.json')
	const { loged } = useApp()
  	const { setModalProps, personData, setPersonData, personFormErr, setPersonFormErr  } = useConfiguration()
	const [hasSubmitted, setHasSubmitted] = useState(false)

	const handleSelect = event => {
		setPersonData(prev => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
		if (hasSubmitted){
			const error = validateFileds(event.target.id, event.target.selected, personData)
            setPersonFormErr(prev => ({...prev, ...error}))
		}
	}

	const handleChange = event => {
		switch (event.target.name) {
			case 'contact_phone':
				setPersonData(prev => ({
					...prev,
					contact_phone: MaskTelefone(event.target.value.replace(/\D/g, '')),
				}))
				break
			case 'cpf':
				setPersonData(prev => ({
					...prev,
					cpf: MaskCpf(event.target.value.replace(/\D/g, '')),
				}))
				break
			default:
				setPersonData(prev => ({
					...prev,
					[event.target.name]: event.target.value,
				}))
		}
		if (hasSubmitted){
			const error = validateFileds(event.target.name, event.target.value, personData)
            setPersonFormErr(prev => ({...prev, ...error}))
		}
	}

	const validateFileds = (field, value, personData) => {
        let error = {};        

        if (field === 'contact_phone') {
            const digitsOnly = value.replace(/\D/g, '');
            if (!digitsOnly) {
                error['contact_phone'] = true;
            }
            if (![10, 11].includes(digitsOnly.length)) {
                error['contact_phone'] = 'Contato inválido';
            } else {
                error['contact_phone'] = '';
            }
        } else if (['birthday_day', 'birthday_month', 'birthday_year'].includes(field)) {
            const { birthday_day, birthday_month, birthday_year } = {
                birthday_day: field === 'birthday_day' ? value : personData.birthday_day,
                birthday_month: field === 'birthday_month' ? value : personData.birthday_month,
                birthday_year: field === 'birthday_year' ? value : personData.birthday_year
            };

            if (!birthday_day) {
                error['birthday_day'] = true
            } else {
                error['birthday_day'] = ''
            }
    
            if (!birthday_month) {
                error['birthday_month'] = true
            } else {
                error['birthday_month'] = ''
            }
    
            if (!birthday_year) {
                error['birthday_year'] = true
            } else {
                error['birthday_year'] = ''
            }
		} else if (field === 'gender') {
			return error
		} else {
		if (!value) {
			error[field] = true;
		} else {
			error[field] = '';
		}}
		return error
}

	const handleSubmit = () => {
		setHasSubmitted(true)
		const payload = new PersonForm(personData)
		let errors = payload.getErrors()
		if (Object.keys(errors).length) return setPersonFormErr(errors)

		dispatch(loading())
		api
			.post('health_place_user/registration/user/create_person/', payload)
			.then(async () => {
				await loged()
				dispatch([
					loaded(),
					AddAlert('Dados pessoais', 'Salvo com sucesso!', AlertType.SUCCESS),
				])
        setModalProps(MODAL_INIT_PROPS)
			})
			.catch(error => {
				dispatch([
					loaded(),
					AddAlert('Dados pessoais', 'Falha ao salvar, revise seus dados e tente novamente!', AlertType.ERROR),
				])
				console.error(error)
			})
	}

	return <div className='PersonalData'>
			<span><b className='Label-Asterisk'>*</b> Nome</span>
			<Input
				name='name'
				value={personData.name}
				action={handleChange}
				errors={{ error: personFormErr, message: personFormErr?.name }}
			/>
			<span><b className='Label-Asterisk'>*</b> E-mail</span>
			<Input
				name='email'
				value={personData?.user?.email}
				disabled
			/>
			<span><b className='Label-Asterisk'>*</b> Sexo</span>
			<NewSelect
				id='sex'
				canBeEmpty={false}
				options={defaultOptions.SEX_CHOICES}
				onSelect={handleSelect}
				selected={personData.sex}
				error={personFormErr?.sex}
			/>
			<span>Gênero</span>
			<NewSelect
				id='gender'
				canBeEmpty={false}
				options={defaultOptions.GENDER_CHOICES}
				onSelect={handleSelect}
				selected={personData.gender}
				error={personFormErr?.gender}
			/>
			<div className='PersonalData-InputsContainerCPFContact'>
				<div>
					<span><b className='Label-Asterisk'>*</b> CPF</span>
					<Input
						name='cpf'
						value={personData.cpf}
						maxLength={14}
						action={handleChange}
						disabled
						errors={{ error: personFormErr, message: personFormErr?.cpf }}
					/>
				</div>
				<div>
					<span><b className='Label-Asterisk'>*</b> Telefone</span>
					<Input
						name='contact_phone'
						maxLength={15}
						value={personData.contact_phone}
						action={handleChange}
						errors={{
							error: personFormErr,
							message: personFormErr?.contact_phone,
						}}
					/>
				</div>
			</div>
			<span><b className='Label-Asterisk'>*</b> Data de nascimento</span>
			<div className='PersonalData-InputsContainerBirthday'>
				<NewSelect
					id='birthday_day'
					defaultText='Dia'
					options={defaultOptions.days}
					onSelect={handleSelect}
					selected={personData.birthday_day}
					error={personFormErr?.birthday_day}
				/>
				<NewSelect
					id='birthday_month'
					defaultText='Mês'
					options={defaultOptions.months}
					onSelect={handleSelect}
					selected={personData.birthday_month}
					error={personFormErr?.birthday_month}
				/>
				<NewSelect
					id='birthday_year'
					defaultText='Ano'
					options={getYearsStart1900()}
					onSelect={handleSelect}
					selected={personData.birthday_year}
					error={personFormErr?.birthday_year}
				/>
			</div>
			<div className='PersonalData-Submit-Box'>
				<div className='PersonalData-Btn-Box'>
					<NewButton
						label='Fechar'
						onClick={() => setModalProps(MODAL_INIT_PROPS)}
						model={ButtonModel.SECONDARY}
					/>
					<NewButton
						label='Salvar'
						onClick={handleSubmit}
					/>
				</div>
				<span className='Label-Mandatory-Filling'>* Preenchimento obrigatório</span>
			</div>
	</div>
}
