import './ReportsPageAttendanceFilters.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AddAlert, AlertType, Input } from '../../../../../../component';
import NewSelect from '../../../../../../newcomponents/form/select/select';
import { uniqueIDs } from '../../../../../../utils/uniqueIDs';
import { stringifyCollaboratorOption } from '../../../../../../utils/stringifyCollaboratorOption';
import { getYearsStart1900 } from '../../../../../../utils/getYearsStart1900';
import { getCollaborators } from '../../../../../collaborators/http';
import { getParams } from '../../../../../../utils';
import { getHealthInsurances } from '../../../../../configuration/http';
import NewButton, { ButtonModel } from '../../../../../../newcomponents/button/button';
import api from '../../../../../../helpers/api';
import { buildDate, formatDateWithTimezone } from '../../../../../../utils/convertDate';
import { loaded, loading } from '../../../../../../layout/redux/AppActions';
import NewEditRoutineModal from '../NewEditRoutine/NewEditRoutine';
import NewMultiSelect from '../../../../../../newcomponents/form/select/multiselect';
import { getCIDTen } from '../../../../../consultation/http';
import { uniqueCIDs } from '../../../../../consultation/subcomponents/SOAP/subcomponents/Assessment/helpers';
import { getSpecialties } from '../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import { useApp } from '../../../../../../layout/App';
import { postQuickEmission } from '../../../../http';

const defaultOptions = require('../../../../../../component/form/select/options.json')

function buildParams(filters) {
    const start__gte = buildDate(filters?.year_start, filters?.month_start, filters?.day_start)
    const start__lte = buildDate(filters?.year_end, filters?.month_end, filters?.day_end)

	const params = {
		"doctor__in": filters?.doctor__in || [],
		"specialty__in": filters?.specialty__in || [],
		"health_insurance__in": filters?.health_insurance__in || [],
		"person__address__city__in": filters?.person__address__city__in || [],
		"classification__in": filters?.classification__in || [],
		"decision__in": filters?.decision__in || [],
		"priority__in": filters?.priority__in || [],
		"result__in": filters?.result__in || [],
		"related_diseases_and_problems__code__in": filters?.related_diseases_and_problems__code__in || [],
        "start__gte": formatDateWithTimezone(start__gte, '00', '00'),
        "start__lte": formatDateWithTimezone(start__lte, '23', '59'),
    }

    return params
}

export default function ReportsPageAttendanceFilters({ filters, setFilters, fetchRoutines=() => null, setModalInfo=() => null }) {
	const dispatch = useDispatch()
	const searchCityTimeout = useRef(null)
	const searchCollaboratorsTimeout = useRef(null)
	const searchHealthInsuranceTimeout = useRef(null)
	const searchSpecialtiesTimeout = useRef(null)
	const searchRelatedDiseasesTimeout = useRef(null)
	const { currentHealthPlaceUser } = useApp()
	const [citiesOptions, setCitiesOptions] = useState([])
	const [collaboratorsOptions, setCollaborators] = useState([])
	const [healthInsurances, setHealthInsurances] = useState([])
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
	const [cidsOptions, setCidsOptions] = useState([])
    const healthPlace = currentHealthPlaceUser?.health_place

	useEffect(() => {
		fetchCities({ offset: 0 })
		fetchCIDTenCodes({ offset: 0 })
        fetchCollaborators({ offset: 0 })
		fetchHealthInsurances({ offset: 0 })
		fetchSpecialtiesOptions({ offset: 0 })
    }, [])
	
	const fetchCIDTenCodes = async (params) => {
        try {
            const cids = await getCIDTen(params)
            setCidsOptions(cids)
        } catch (err) {
            setCidsOptions([])
        }
    }

    const fetchCollaborators = async params => {
		await getCollaborators({
			...params,
			health_place__id: healthPlace?.id,
			limit: 500,
			profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
			has_person: true
		})
			.then(res => setCollaborators(res.data.results.map(instance => stringifyCollaboratorOption(instance))))
			.catch(err => {
				console.error('fetchCollaborators', err)
				setCollaborators([])
			})
	}
	
	const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

	const fetchHealthInsurances = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await getHealthInsurances(params)
			setHealthInsurances(res.data.results)
		} catch (err) {
			console.error('fetchHealthInsurances ~ ', err)
			dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
		}
	}

	const fetchCities = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await api.get(`address/city/${params}`)
			setCitiesOptions(res.data.results)
		} catch (err) {
			console.error('fetchCities ~ ', err)
			dispatch(AddAlert('Listar cidades', 'Erro ao obter cidades', AlertType.ERROR))
		}
	}

	const handleSearchCollaborators = event => {
		if (searchCollaboratorsTimeout.current)
			clearTimeout(searchCollaboratorsTimeout.current)

		searchCollaboratorsTimeout.current = setTimeout(() => {
			fetchCollaborators({ name_cpf_or_email: event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchHealthInsurance = (event) => {
		if (searchHealthInsuranceTimeout.current) clearTimeout(searchHealthInsuranceTimeout.current)
	
		searchHealthInsuranceTimeout.current = setTimeout(() => {
			fetchHealthInsurances({ 'name': event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchCity = (event) => {
		if (searchCityTimeout.current) clearTimeout(searchCityTimeout.current)

		searchCityTimeout.current = setTimeout(() => {
			fetchCities({ 'name': event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchSpecialties = (event) => {
        if (searchSpecialtiesTimeout.current) clearTimeout(searchSpecialtiesTimeout.current)

        searchSpecialtiesTimeout.current = setTimeout(() => {
            fetchSpecialtiesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

	const searchRelatedDiseasesProblems = (search) => {
        if (searchRelatedDiseasesTimeout.current) clearTimeout(searchRelatedDiseasesTimeout.current)

        searchRelatedDiseasesTimeout.current = setTimeout((search) => {
            fetchCIDTenCodes({ search });
        }, 400, search)
    };

	const handleSelect = event => {
		setFilters(prev => ({ ...prev, [event.target.id]: event?.target?.selected || null }))
	}

	const handleExportXLS = () => {
		const params = buildParams(filters)

		dispatch(loading())
		api.post('/reports/export_csv', { params })
			.then(res => {
				dispatch([loaded(), AddAlert('Relatório de atendimento', 'CSV gerado com sucesso', AlertType.SUCCESS)])
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'historico-de-atendimentos.csv'); 
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch(() => {
                dispatch([loaded(), AddAlert('Relatório de atendimento', 'Não foi possível gerar o relatório', AlertType.ERROR)])
            })
	}

    const handleEmission = () => {
        const params = buildParams(filters)

		dispatch(loading())
		postQuickEmission({ params })
            .then(res => {
                dispatch([loaded(), AddAlert('Relatório de atendimento', 'Relatório gerado com sucesso', AlertType.SUCCESS)])
                const link = document.createElement('a')
                link.href = res.data.base64
                link.download = 'historico-de-atendimentos.pdf'
                link.click()
            })
            .catch(() => {
                dispatch([loaded(), AddAlert('Relatório de atendimento', 'Não foi possível gerar o relatório', AlertType.ERROR)])
            })
    }

    const handleNewRoutine = () => {
        setModalInfo({ 
			open: true, 
			content: <NewEditRoutineModal
				initRoutine={{ appointment_params: filters }}
				setFilters={setFilters}
				fetchRoutines={fetchRoutines} 
				setModalInfo={setModalInfo} 
			/>,
			title: 'Salvar rotina'
		})
    }

	const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = filters[loadKey].find(option => option[fieldNameRef] === optionRef);
	
		if (isExisting) {
			setFilters(prev => ({
				...prev,
				[loadKey]: prev[loadKey].filter(option => option[fieldNameRef] !== optionRef)
			}));
		} else {
			setFilters(prev => ({
				...prev,
				[loadKey]: [...prev[loadKey], selectedOption]
			}));
		}
	};

    return <section className='ReportsPageAttendanceFilters'>
			<p><b>Relatório por atendimentos</b></p>
			<div className='ReportsPageAttendanceFilters-Table'>
				<div className='ReportsPageAttendanceFilters-Table-DefaultSelect'>
				<div className='ReportsPageAttendanceFilters-Select-Date'>
						<NewSelect
							id='day_start'
							defaultText='Dia'
							label='De'
							options={defaultOptions.days}
							onSelect={handleSelect}
							selected={filters?.day_start}
						/>
						<NewSelect
							id='month_start'
							defaultText='Mês'
							options={defaultOptions.months}
							onSelect={handleSelect}
							selected={filters?.month_start}
						/>
						<NewSelect
							id='year_start'
							defaultText='Ano'
							options={getYearsStart1900()}
							onSelect={handleSelect}
							selected={filters?.year_start}
						/>
					</div>
					<div className='ReportsPageAttendanceFilters-Select-Date'>
						<NewSelect
							id='day_end'
							defaultText='Dia'
							label='Até'
							options={defaultOptions.days}
							onSelect={handleSelect}
							selected={filters?.day_end}
						/>
						<NewSelect
							id='month_end'
							defaultText='Mês'
							options={defaultOptions.months}
							onSelect={handleSelect}
							selected={filters?.month_end}
						/>
						<NewSelect
							id='year_end'
							defaultText='Ano'
							options={getYearsStart1900()}
							onSelect={handleSelect}
							selected={filters?.year_end}
						/>
					</div>
					<NewMultiSelect
						label='Profissionais'
						filterNode={
							<div className='ManageTeamEdgesModal-NewSelect'>
								<Input
									placeholder='Pesquisar por e-mail, nome ou cpf'
									action={handleSearchCollaborators}
									actionFocus={() => fetchCollaborators()}
								/>
							</div>
						}
						defaultText={filters.doctor__in?.length ? `${filters.doctor__in.length} selecionados` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('doctor__in', collaboratorsOptions, 'id', optionRef)}
						selectedOptions={filters.doctor__in?.map(doctor => doctor.id)}
						optionRefKey='id'
						optionStrKey='str'
						options={collaboratorsOptions}
					/>
					<NewMultiSelect
						label='Especialidades'
						filterNode={
							<div className='NewAppointmentModal-NewSelect'>
								<Input
									placeholder='Pesquisar por nome'
									action={handleSearchSpecialties}
									actionFocus={() => fetchSpecialtiesOptions()}
								/>
							</div>
						}
						defaultText={filters.specialty__in?.length ? `${filters.specialty__in.length} selecionadas` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('specialty__in', specialtiesOptions, 'id', optionRef)}
						selectedOptions={filters.specialty__in?.map(specialty => specialty.id)}
						optionRefKey='id'
						optionStrKey='strf'
						options={specialtiesOptions}
					/>
					<NewMultiSelect
						label='Convênios'
						filterNode={
							<div className='NewAppointmentModal-NewSelect'>
								<Input
									placeholder='Pesquisar por nome'
									action={handleSearchHealthInsurance}
									actionFocus={() => fetchHealthInsurances()}
								/>
							</div>
						}
						defaultText={filters.health_insurance__in?.length ? `${filters.health_insurance__in.length} selecionados` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('health_insurance__in', healthInsurances, 'id', optionRef)}
						selectedOptions={filters.health_insurance__in?.map(insurance => insurance.id)}
						optionRefKey='id'
						optionStrKey='name'
						options={healthInsurances}
					/>
					<NewMultiSelect
						label='Categoria'
						filterNode={null}
						defaultText={filters.classification__in.length ? `${filters.classification__in.length} selecionadas` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('classification__in', defaultOptions.CLASSIFICATION_CHOICES, 'id', optionRef)}
						selectedOptions={filters.classification__in.map(classification => classification.id)}
						optionRefKey='id'
						optionStrKey='name'
						options={defaultOptions.CLASSIFICATION_CHOICES}
					/>
					<NewMultiSelect
						label='Status'
						filterNode={null}
						defaultText={filters.decision__in.length ? `${filters.decision__in.length} selecionadas` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('decision__in', defaultOptions.DECISION_CHOICES, 'id', optionRef)}
						selectedOptions={filters.decision__in.map(decision => decision.id)}
						optionRefKey='id'
						optionStrKey='name'
						options={defaultOptions.DECISION_CHOICES}
					/>
					<NewMultiSelect
						label='Prioridade'
						filterNode={null}
						defaultText={filters.priority__in.length ? `${filters.priority__in.length} selecionadas` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('priority__in', defaultOptions.PRIORITY_CHOICES, 'id', optionRef)}
						selectedOptions={filters.priority__in.map(priority => priority.id)}
						optionRefKey='id'
						optionStrKey='name'
						options={defaultOptions.PRIORITY_CHOICES}
					/>
					<NewMultiSelect
						label='Tipo'
						filterNode={null}
						defaultText={filters.result__in.length ? `${filters.result__in.length} selecionados` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('result__in', defaultOptions.RESULT_CHOICES, 'id', optionRef)}
						selectedOptions={filters.result__in.map(result => result.id)}
						optionRefKey='id'
						optionStrKey='name'
						options={defaultOptions.RESULT_CHOICES}
					/>
					<NewMultiSelect
						label='Residência do Paciente'
						filterNode={
							<div className='NewAppointmentModal-NewSelect'>
								<Input 
									placeholder='Pesquisar por cidade'
									action={handleSearchCity}
									actionFocus={()=> fetchCities()}
								/>
							</div>
						}
						defaultText={filters.person__address__city__in.length ? `${filters.person__address__city__in.length} selecionados` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('person__address__city__in', citiesOptions, 'id', optionRef)}
						selectedOptions={filters.person__address__city__in.map(option => option.id)}
						optionRefKey='id'
						optionStrKey='name'
						options={citiesOptions}
					/>
					<NewMultiSelect
						label='CIDs'
						filterNode={<div className='SOAP-Assessment-NewMultiSelect-FilterNode'>
							<Input 
								placeholder='Pesquisar por CID ou Título'
								action={({ target: { value } }) => searchRelatedDiseasesProblems(value)}
								actionFocus={()=> fetchCIDTenCodes()}
							/>
						</div>}
						defaultText={filters.related_diseases_and_problems__code__in.length ? `${filters.related_diseases_and_problems__code__in.length} selecionados` : 'Selecione'}
						onlyDefaultText
						onSelect={({ optionRef }) => toggleOption('related_diseases_and_problems__code__in', cidsOptions, 'code', optionRef)}
						selectedOptions={filters.related_diseases_and_problems__code__in?.map(cid => cid.code) || []}
						optionRefKey='code'
						optionStrKey='title'
						options={filters.related_diseases_and_problems__code__in
							? uniqueCIDs([...filters.related_diseases_and_problems__code__in, ...cidsOptions]).map(cid => ({ ...cid, title: `${cid.code} | ${cid.title}` }))
							: []
						}
					/>
				</div>
			</div>
			<div className='ReportsPageAttendanceFilters-ActionRow'>
				<NewButton
                    label='Salvar rotina'
                    model={ButtonModel.SECONDARY}
                    onClick={handleNewRoutine}
                />
				<NewButton
                    label='Exportar XLS'
                    onClick={handleExportXLS}
                />
                <NewButton
                    label='Imprimir'
                    onClick={handleEmission}
                />
            </div>
		</section>
} 