export const formatScheduleDateWithTimezone = (year, month, day, time) => {
    let hour, minute;
    [hour, minute] = time.split(':')
    let date = new Date(year, month - 1, day, hour, minute)
    let offset = date.getTimezoneOffset() * 60000;
    let dateISOTime = new Date(date.getTime() - offset).toISOString()
  
    return dateISOTime
}

export function generateTimeSlots(event, gap) {
  if (!event.data || !event.tempo || !event.tempoConsulta) {
    throw new Error('Missing required event properties');
  }
  
  const schedule = [];
  let startDate = convertToDateTime(event.data, event.tempo.de);
  const endDate = convertToDateTime(event.data, event.tempo.ate);
  const interval = parseInt(event.tempoConsulta.duration, 10); // Appointment duration in minutes

  while (true) {
    let endSlotTime = new Date(startDate.getTime() + interval * 60000);

    if (endSlotTime.getTime() > endDate.getTime()) {
      break;
    }

    schedule.push({
      de: formatTime(startDate),
      ate: formatTime(endSlotTime),
    });

    startDate = new Date(endSlotTime.getTime() + gap * 60000);
  }

  return schedule;
}
  
function convertToDateTime(date, time) {
    const [day, month, year] = date.split('/');
    const [hours, minutes] = time.split(':');
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
}
  
function calculateNumberOfSlots(start, end, interval) {
    const durationInMinutes = (end - start) / 60000;
    return Math.ceil(durationInMinutes / interval);
}
  
function formatTime(date) {
    return `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;
}
  
function padZero(number) {
    return number.toString().padStart(2, '0');
}