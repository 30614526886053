import './HealthPlaceGeneralData.css'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Input,
	MaskCNPJ,
	MaskTelefone,
	Select,
} from '../../../../../../../../../component'
import NewButton, {
	ButtonModel,
} from '../../../../../../../../../newcomponents/button/button'
import { useHealthPlaceForm } from '../../SaveHealthPlaceModal'
import { useConfiguration } from '../../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../../newcomponents/modal/modal'
import {
	AddAlert,
	AlertType,
} from '../../../../../../../../../component/alert/actions'
import { useApp } from '../../../../../../../../../layout/App'
import { stringifyCollaboratorOption } from '../../../../../../../../../utils/stringifyCollaboratorOption'
import { getCollaborators } from '../../../../../../../../collaborators/http'

export default function HealthPlaceGeneralData() {
	const dispatch = useDispatch()
	const { setModalProps } = useConfiguration()
	const { currentHealthPlaceUser } = useApp()
	const { selectedHealthPlace, setSelectedHealthPlace, onNext } = useHealthPlaceForm()
	const { listas } = useSelector((state) => state.appState)
	const [collaboratorsOptions, setCollaboratorsOptions] = useState([])
	const [validacao, setValidacao] = useState({
		cnpf: false,
		contact_whatsapp: false,
		name: false,
		type: false,
		technical_responsible: false,
		cnes: false
	})

	useEffect(() => {
		fetchCollaboratorsOptions({offset: 0})
	}, [])

	const fetchCollaboratorsOptions = async params => {
		await getCollaborators({
			...params,
			health_place__id: currentHealthPlaceUser?.health_place?.id,
            is_active: true,
			limit: 500,
			has_person: true
		})
			.then(res => {
                setCollaboratorsOptions(res.data.results.map(instance => stringifyCollaboratorOption(instance)))})
			.catch(err => {
				console.error('fetchCollaboratorsOptions', err)
				setCollaboratorsOptions([])
			})
	}

	const handleChange = ({ target }) => {
		const { name, value } = target
		setSelectedHealthPlace((prev) => ({ ...prev, [name]: value }))
	}

	const handleValidate = (event, v) => {
		if (event) {
			setValidacao({ ...validacao, [v.campo]: v.mensagem })
		}
	}

	const handleNext = () => {
		const validation = Object.values(validacao).some(
			(valor) => valor !== false && valor !== undefined
		)
		if (validation) {
			dispatch(
				AddAlert(
					'Erro na validação',
					'Campos obrigatórios vazios ou valor inválido',
					AlertType.ERROR
				)
			)
			return
		}
		onNext()
	}

	return (
		<div className='HealthPlaceForm-Body HealthPlaceGeneralData'>
			<div>
				<b>Nome</b>
				<Input
					name='name'
					action={(e) => handleChange(e)}
					actionBlur={(e, v) => handleValidate(e, v)}
					value={selectedHealthPlace.name}
					required={{
						erro: validacao,
						message: 'Campo obrigatório',
					}}
				/>
			</div>
			<div className='HealthPlaceGeneralData-2FR'>
				<div>
					<b>Contato (Whatsapp)</b>
					<Input
						name='contact_whatsapp'
						action={(e) => handleChange(e)}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={MaskTelefone(selectedHealthPlace.contact_whatsapp)}
						maxLength='15'
						required={{
							pattern: 'phone',
							erro: validacao,
							message:
								validacao.contact_whatsapp === 'invalido'
									? 'Preencha Telefone valido'
									: 'Campo obrigatório',
						}}
					/>
				</div>
				<div className='HealthPlaceGeneralData-Form-2Column'>
					<div>
						<b>Tipo</b>
						<Select
							name='type'
							action={(e) => handleChange(e)}
							actionClose={(e, v) => handleValidate(e, v)}
							options={listas.tipos}
							selected={selectedHealthPlace.type ? selectedHealthPlace.type : {}}
							required={{
								erro: validacao,
								message: 'Campo obrigatório',
							}}
						/>
					</div>
					<div>
						<b>Responsável Técnico</b>
						<Select
							name='technical_responsible'
							action={(e) => handleChange(e)}
							actionClose={(e, v) => handleValidate(e, v)}
							options={collaboratorsOptions}
							selected={selectedHealthPlace.technical_responsible ? selectedHealthPlace.technical_responsible : {}}
							required={{
								erro: validacao,
								message: 'Campo obrigatório',
							}}
						/>
					</div>
				</div>
			</div>
			<div>
				<b>CNPJ</b>
				<Input
					name='cnpj'
					action={(e) => handleChange(e)}
					actionBlur={(e, v) => handleValidate(e, v)}
					value={
						selectedHealthPlace.cnpj
							? MaskCNPJ(selectedHealthPlace.cnpj)
							: ''
					}
					maxLength='18'
					required={{
						pattern: 'cnpj',
						erro: validacao,
						message:
							validacao.cnpj === 'invalido'
								? 'Preencha CNPJ valido'
								: 'Campo obrigatório',
					}}
				/>
			</div>
			<div>
					<b>CNES</b>
					<Input
						name='cnes'
						action={(e) => handleChange(e)}
						actionBlur={(e, v) => handleValidate(e, v)}
						value={selectedHealthPlace.cnes}
						maxLength='7'
						required={{
							erro: validacao,
							message: 'Campo obrigatório',
						}}
					/>
			</div>
			<div className='HealthPlaceForm-Btn-Box'>
				<NewButton
					model={ButtonModel.SECONDARY}
					label='Fechar'
					onClick={() => setModalProps(MODAL_INIT_PROPS)}
				/>
				<NewButton
					model={ButtonModel.PRIMARY}
					label=' Avançar'
					onClick={handleNext}
				/>
			</div>
		</div>
	)
}
