import './HealthPlaceInstitutionalLegalData.css'
import React, { useEffect, useState } from "react";
import { useConfiguration } from '../../../../../configuration';
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import { MaskCNPJ, MaskTelefone } from '../../../../../../../component';
import HealthPlaceLegalDataModal from './HealthPlaceLegalDataModal/HealthPlaceLegalDataModal';
import { getCollaboratorById } from '../../../../../../collaborators/http';

const { HEALTH_PLACE_TYPE_CHOICES } = require('../../../../../../../component/form/select/options.json')


export default function HealthPlaceInstitutionalLegalData() {
    const { institution, setModalProps } = useConfiguration()
    const [technicalResponible, setTechnicalResponsible] = useState()

    useEffect(async () => {
        await getCollaboratorById(institution.technical_responsible)
        .then(res => setTechnicalResponsible(res.data.user.person.name))
        .catch(err => {
            console.error('fetchTechnicalResponsible', err)
            setTechnicalResponsible(null)
        })
    },[])

    return <div className='HealthPlaceInstitutionalLegalData'>
        <div className='HealthPlaceInstitutionalLegalData-Header'>
            <div className='HealthPlaceInstitutionalLegalData-Header-Icon'>D</div>
            <div className='HealthPlaceInstitutionalLegalData-Header-Title'>Dados Jurídicos</div>
        </div>
        <div>
            <HorizontalField
                label='Nome'
                content={institution?.name || '-'}
            />
            <HorizontalField
                label='CNPJ'
                content={typeof institution.cnpj === 'string' ? MaskCNPJ(institution.cnpj) : institution.cnpj || '-'}
            />
            <HorizontalField
                label='CNES'
                content={institution?.cnes || '-'}
            />
            <HorizontalField 
                label='Tipo'
                content={HEALTH_PLACE_TYPE_CHOICES.find(item => item.id === institution?.type)?.name || '-'}
            />
            <HorizontalField 
                label='Contato'
                content={MaskTelefone(institution.contact_whatsapp) || '-'}
            />
            <HorizontalField 
                label='Responsável Técnico'
                content={technicalResponible || '-'}
            />
        </div>
        <div className='HealthPlaceInstitutionalLegalData-ActionRow'>
            <div
                className='HealthPlaceInstitutionalLegalData-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Editar Instituição',
                    content: <HealthPlaceLegalDataModal />,
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}