export const handleSortByName = (list, key, order) => {
  return list.sort((a, b) => {
    if (a[key] < b[key]) return order * (-1);
    if (a[key] > b[key]) return  order;
    return 0;
  });
}

export function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  while (currentIndex != 0) {

    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}