import './PersonalDataCouncilManagerModal.css'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType, Button, IcoBin, IcoPencil, Input, List } from '../../../../../../../../component'
import NewSelect from '../../../../../../../../newcomponents/form/select/select'
import NewButton, {ButtonModel} from '../../../../../../../../newcomponents/button/button'
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions'
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { useConfiguration } from '../../../../../../configuration'
import { useApp } from '../../../../../../../../layout/App'
import { buildDate } from '../../../../../../../../utils/convertDate'
import { patchCouncil, postCouncil } from '../../../../../../http'
import EditCouncilModal from '../EditCouncilModal/EditCouncilModal'


const strpCouncilData = (data) => {
	return {
		"id": data?.id || null,
		"council": data?.council || "",
		"nu_crm": data?.nu_crm || "",
		"uf_crm": data?.uf_crm || "",
		"person": data?.person || "",
		"registration_date": data?.registration_date || "",
		"specialties": data?.specialties || ""
	}
}

const councilHead = [
	{ colunm: 'h_nu_crm', text: 'Número' },
	{ colunm: 'h_uf_crm', text: 'UF' },
	{ colunm: 'h_actions', text: '', width: '100px'  },
]

export default function PersonalDataCouncilManagerModal({ initCouncilData, councils }) {
	const options = require('../../../../../../../../component/form/select/options.json')
	const dispatch = useDispatch()
	const [holdingCouncil, setHoldingCouncil] = useState(strpCouncilData(initCouncilData))
	const [holdingCouncilErr, setHoldingCouncilErr] = useState({})
	const { listas } = useSelector(state => state.appState)
	const { setModalProps } = useConfiguration()
	const [secondaryModalProps, setSecondaryModalProps] = useState(MODAL_INIT_PROPS)
	const { fetchUserCouncils, currentHealthPlaceUser } = useApp()

	let profile = currentHealthPlaceUser?.profile

	const councilNames = {
		DENTIST: 'CRO',
		PSYCHOLOGIST: 'CRP',
		NUTRITIONIST: 'CRN',
		NURSE: 'COREN',
	}

	let selected_council_by_profile = councilNames[profile]

	const handleChange = event => {
		setHoldingCouncilErr({})
		setHoldingCouncil(prev => ({
			...prev,
			[event.target.name]:
				event.target.value[0] === 't'
					? event.target.value
					: event.target.value.replace(/\D/g, ''),
		}))
	}

	const handleClose = () => {
		setModalProps(MODAL_INIT_PROPS)
		setHoldingCouncil(initCouncilData)
	}

	const handleSelect = event => {
		setHoldingCouncilErr({})
		setHoldingCouncil(prev => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

	const handleRemove = async (selectedCouncil) => {
		dispatch(loading())
		try {
			await patchCouncil(selectedCouncil?.id, { situation: 'D'})
			dispatch([loaded(), AddAlert(`Remoção de ${councilNames[profile]}`, `${councilNames[profile]} removido com sucesso`, AlertType.SUCCESS)])
			handleClose()
		} catch (err) {
			console.error('handleRemove', err)
		}
		fetchUserCouncils()
	}

	const handleSaveCouncil = async () => {
		let errors = {}
		if (!holdingCouncil.nu_crm) errors['nu_crm'] = 'Inscrição obrigatória'
		if (!holdingCouncil.uf_crm) errors['uf_crm'] = 'UF obrigatória'
		if (Object.keys(errors).length) return setHoldingCouncilErr(errors)

		let currentDate = new Date()
		let payload = {
			...holdingCouncil,
			'person': currentHealthPlaceUser?.user?.person?.id,
			'council': selected_council_by_profile,
			'registration_date': buildDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
		}

		dispatch(loading())
		try {
			if (holdingCouncil?.id) {
				await patchCouncil(holdingCouncil?.id, payload)
			} else {
				await postCouncil(payload)
			}
			fetchUserCouncils()
			dispatch([
				loaded(),
				AddAlert(
					`Cadastro de ${selected_council_by_profile}`,
					`${selected_council_by_profile} salvo com sucesso`,
					AlertType.SUCCESS
				),
			])
			setModalProps(MODAL_INIT_PROPS)
		} catch (err) {
			console.error(err)
			dispatch([
				loaded(),
				AddAlert(
					`Cadastro de ${selected_council_by_profile}`,
					`Falha ao salvar ${selected_council_by_profile}, tente novamente!`,
					AlertType.ERROR
				),
			])
		}
	}

	const specialtiesOptions = {
		DENTIST: options.cro_specialties,
		PSYCHOLOGIST: options.crp_specialties,
		NUTRITIONIST: options.crn_specialties,
		NURSE: options.coren_specialties,
	}

	return (
		<div className='GenericCouncilManagerModal'>
			<Modal
					dismissText='Fechar'
					dismissFn={() => setSecondaryModalProps(MODAL_INIT_PROPS)}
					{...secondaryModalProps}
				/>
			<List
				head={councilHead}
				data={councils.map(instance => ({ ...instance }))}
				noData={''}
				maxHeight='200px'
				listCustom={council => {
					const custom = council
					custom['h_nu_crm'] = council.nu_crm
					custom['h_uf_crm'] = council.uf_crm
					custom['h_actions'] = (
						<div className='GenericCouncilManagerModal-Actions'>
							<Button
								color='secondary'
								type='btn circle'
								action={() => {
									setSecondaryModalProps(prev => ({
										...prev,
										open: true,
										title: 'Conselho profissional',
										content: (
											<EditCouncilModal initCouncilData={council} setSecondaryModalProps={setSecondaryModalProps}/>
										),
										dismissText: '',
									}))
								}}
								title='Editar'>
								<IcoPencil />
							</Button>
							<Button
								color='danger'
								type='btn circle'
								action={() => handleRemove(council)}
								title='Remover'>
								<IcoBin />
							</Button>
						</div>
					)
					return custom
				}}
			/>
			<b className='GenericCouncilManagerModal-Subtitle'>Adicionar {councilNames[profile]}</b>
			<div>
				<Input
					label='Inscrição'
					name='nu_crm'
					action={handleChange}
					value={holdingCouncil.nu_crm}
					maxLength='8'
					required={{
						erro: holdingCouncilErr,
						message: holdingCouncilErr.nu_crm,
					}}
					placeholder='ex: 123456'
				/>
				<NewSelect
					id='uf_crm'
					options={listas.estados}
					canBeEmpty={false}
					label='UF'
					optionRefKey='sigla'
					optionStrKey='nome'
					selected={holdingCouncil.uf_crm}
					onSelect={handleSelect}
					error={holdingCouncilErr?.uf_crm}
				/>
			</div>
			<NewSelect
				id='specialties'
				label='Especialidade'
				optionRefKey='name'
				options={specialtiesOptions[profile] || []}
				selected={holdingCouncil.specialties}
				onSelect={handleSelect}
			/>
			<div className='GenericCouncilManagerModal-SaveBtn'>
				<NewButton
					label='Fechar'
					onClick={handleClose}
					model={ButtonModel.SECONDARY}
				/>
				<NewButton
					label={`Salvar ${councilNames[profile]}`}
					onClick={handleSaveCouncil}
				/>
			</div>
		</div>
	)
}
