import './FilterInventoryParams.css'
import React, { useEffect, useRef, useState } from "react";
import NewSelect from "../../../../../../../../newcomponents/form/select/select";
import { Input } from "../../../../../../../../component";
import { getInventoryMaterialCategories } from "../../../ConfigInventoryMaterials/subcomponents/CategoriesOptions/http";
import { getInventoryMaterialLocations } from "../../../ConfigInventoryMaterials/subcomponents/LocationsOptions/http";
import { getInventoryMaterialManufacturers } from '../../../ConfigInventoryMaterials/subcomponents/ManufacturersOptions/http';
import IcoExcess from '../../../../../../../../component/icon/box_barcode';
import IcoCleanFilter from '../../../../../../../../component/icon/cleanFilter';
import InsertInventoryMaterialsModal from '../InsertInventoryMaterialsModal/InsertInventoryMaterialsModal';
import { BoxIcon } from '../../../../../../../../component/icon/box';
import { useInventoryMaterials } from '../../../../InventoryMaterials';

const { INVENTORY_MATERIAL_ORDERING_OPTIONS } = require('../../../../../../../../component/form/select/options.json')

export default function FilterInventoryParams({ filterParams={}, setFilterParams= ()=> null, fetchMaterials=() => null, setModalInfo=() => null, INIT_FILTER_PARAMS={} }) {
    const searchTimeout = useRef(null)
    const selectOptionsSearchTimeout = useRef(null)
    const [filtersList, setFiltersList] = useState([])
    const [activeCategories, setActiveCategories] = useState([])
    const [activeLocations, setActiveLocations] = useState([])
    const [activeManufacturers, setActiveManufacturers] = useState([])
    const { canReadAndWrite } = useInventoryMaterials()

    useEffect(() => {
        fetchMaterials({ ...filterParams, "offset": 0 })
    }, [])

    useEffect(() => {
        fetchActiveCategoriesOptions()
        fetchActiveLocationsOptions()
        fetchActiveManufacturersOptions()
    }, [])

    const updateFilterList = (newParam={}) => {
        const tempFilterList = Object.entries({ ...filterParams, ...newParam })
 
        setFiltersList(() => {
            const newFilters = tempFilterList
                .filter(item => (item[1] !== '' && item[1] !== null && item[1] !== undefined) && item[0] !== 'offset' && item[0] !== 'ordering')
                .map(item => {
                    switch (item[0]) {
                        case 'name__icontains':
                            return ['Nome', 'name__icontains']
                        case 'code__icontains':
                            return ['Código', 'code__icontains']
                        case 'category':
                            return ['Categoria ', 'category']
                        case 'manufacturer':
                            return ['Fabricante ', 'manufacturer']
                        case 'location':
                            return ['Localização ', 'location']
                        default:
                            return item[0]
                    }
                })
                return newFilters
        })
    }
    
    const clearFilter = async (targetFilter) => {
        if (targetFilter === 'all') {
            setFiltersList([])
            setFilterParams(INIT_FILTER_PARAMS)
            await fetchMaterials(INIT_FILTER_PARAMS)
        } else if (targetFilter) {
            setFiltersList(filtersList.filter(item => item[1] != targetFilter))
            setFilterParams(async prev => {
                let newFilter = { ...prev, [targetFilter]: '' }
                await fetchMaterials(newFilter)
    
                return newFilter
            })
        }
    }

    const fetchActiveCategoriesOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialCategories({ active: true, ...params })
            setActiveCategories(res.data.results)
        } catch (err) {
            setActiveCategories([])
            console.error('fetchActiveCategoriesOptions ~ ', err)
        }
    }

    const fetchActiveLocationsOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialLocations({ active: true, ...params })
            setActiveLocations(res.data.results)
        } catch (err) {
            setActiveLocations([])
            console.error('fetchActiveLocationsOptions ~ ', err)
        }
    }

    const fetchActiveManufacturersOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialManufacturers({ active: true, ...params })
            setActiveManufacturers(res.data.results)
        } catch (err) {
            setActiveManufacturers([])
            console.error('fetchActiveManufacturersOptions ~ ', err)
        }
    }

    const handleChange = (event) => {
        setFilterParams(prev => ({ ...prev, [event.target.name]: event.target.value }))
    
        try {
            searchTimeout.current && clearTimeout(searchTimeout.current)
            searchTimeout.current = setTimeout(async () => {
                let fetchParams = { ...filterParams, [event.target.name]: event.target.value, offset: 0 }
                await fetchMaterials(fetchParams)
                updateFilterList(fetchParams)
            }, 500)
        } catch (err) {
            console.error('FilterInventoryParams ~ handleChange ~ ', err)
        }
    }

    const handleSelect = (event) => {
        setFilterParams(prev => ({ ...prev, [event.target.id]: event.target.selected }))

        try {
            searchTimeout.current && clearTimeout(searchTimeout.current)
            searchTimeout.current = setTimeout(async () => {
                let fetchParams = { ...filterParams, [event.target.id]: event.target.selected, offset: 0 }
                await fetchMaterials(fetchParams)
                updateFilterList(fetchParams)
            }, 300)
        } catch (err) {
            console.error('FilterInventoryParams ~ handleSelect ~ ', err)
        }
    }

    const handleSearchCategory = (event) => {
        if (selectOptionsSearchTimeout.current) clearTimeout(selectOptionsSearchTimeout.current)
    
        selectOptionsSearchTimeout.current = setTimeout(() => {
                fetchActiveCategoriesOptions({ 'name__icontains': event.target.value })
          }, 300, event.target.value)
    }

    const handleSearchLocation = (event) => {
        if (selectOptionsSearchTimeout.current) clearTimeout(selectOptionsSearchTimeout.current)
    
        selectOptionsSearchTimeout.current = setTimeout(() => {
                fetchActiveLocationsOptions({ 'name__icontains': event.target.value })
          }, 400, event.target.value)
    }

    const handleSearchManufacturer = (event) => {
        if (selectOptionsSearchTimeout.current) clearTimeout(selectOptionsSearchTimeout.current)
    
        selectOptionsSearchTimeout.current = setTimeout(() => {
                fetchActiveManufacturersOptions({ 'name__icontains': event.target.value })
          }, 400, event.target.value)
    }

    return <div className='ListInventoryMaterials-Filters'>
        <div className='FilterInventoryParams-SideBarLeft-ActionRow'>
            {
                canReadAndWrite
                ? <div
                    onClick={() => setModalInfo(prev => ({ ...prev, 
                        open: true, 
                        title: '', 
                        content: <InsertInventoryMaterialsModal fetchMaterials={fetchMaterials} /> 
                    }))}
                    title="Novo Material"
                >
                    <BoxIcon />
                </div>
                : null
            }
            
            <div
                onClick={() => clearFilter('all')}
                title="Limpar filtro"
            >
                <IcoCleanFilter />
            </div>
        </div>
        <div className='ListInventoryMaterials-Filters-Body'>
            <div className='ListInventoryMaterials-Select'>
                <span>Nome</span>
                <Input
                    name='name__icontains'
                    action={handleChange}
                    value={filterParams.name__icontains}
                />
            </div>
            {
                /*
            <div className='ListInventoryMaterials-Select'>
                <span>Tipo do Produto</span>
                <NewSelect 
                    onSelect={() => null}
                    selected=''
                    options={[]}
                />
            </div>
                */
            }

            <div className='ListInventoryMaterials-Input'>
                <span>Código</span>
                <Input
                    name='code__icontains'
                    action={handleChange}
                    value={filterParams.code__icontains}
                />
            </div>
            <div className='ListInventoryMaterials-Select'>
                <span>Categoria</span>
                <NewSelect 
                    id='category'
                    onSelect={handleSelect}
                    selected={filterParams.category}
                    options={activeCategories}
                    optionStrKey='name'
                    filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                        <Input
                            placeholder='Pesquisar por categoria'
                            action={handleSearchCategory}
                            actionFocus={()=> fetchActiveCategoriesOptions()}
                        />
                    </div>}
                />
            </div>
            <div className='ListInventoryMaterials-Select'>
                <span>Localização</span>
                <NewSelect 
                    id='location'
                    onSelect={handleSelect}
                    selected={filterParams.location}
                    options={activeLocations}
                    optionStrKey='name'
                    filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                        <Input
                            placeholder='Pesquisar por localização'
                            action={handleSearchLocation}
                            actionFocus={()=> fetchActiveLocationsOptions()}
                        />
                    </div>}
                />
            </div>
            <div className='ListInventoryMaterials-Select'>
                <span>Fabricante</span>
                <NewSelect 
                    id='manufacturer'
                    onSelect={handleSelect}
                    selected={filterParams.manufacturer}
                    options={activeManufacturers}
                    optionStrKey='name'
                    filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                        <Input
                            placeholder='Pesquisar por fabricante'
                            action={handleSearchManufacturer}
                            actionFocus={()=> fetchActiveManufacturersOptions()}
                        />
                    </div>}
                />
            </div>
            <div className='ListInventoryMaterials-Select'>
                <span>Filtrar por</span>
                <NewSelect 
                    id='ordering'
                    onSelect={handleSelect}
                    selected={filterParams.ordering}
                    options={INVENTORY_MATERIAL_ORDERING_OPTIONS}
                />
            </div>
        </div>
        {
            filtersList?.length
            ? <div className='FilterInventoryParams-ActiveFilters'>
                <p className='FilterInventoryParams-Title'>Filtros Ativos:</p>
                <ul className='FilterInventoryParams-List'>
                    {filtersList.map((item, index) => { 
                        return (
                            <li key={index} className=''>
                                <button onClick={() => clearFilter(item[1])} className='FilterInventoryParams-Button'>
                                    <p className='FilterInventoryParams-Name'>
                                        {item[0]}
                                    </p>
                                    <div className='FilterInventoryParams-Separator'/>
                                    <span className='FilterInventoryParams-Remove'>x</span>
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </div>
            : null
        }
    </div>
}