import './InsertInventoryMaterialsModal.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import { AddAlert, AlertType, Input } from '../../../../../../../../component';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { NewCheckbox } from '../../../../../../../../newcomponents';
import { getYearsRangeTodayToPlus50 } from '../../../../../../../../utils/getYearsRangeTodayToPlus50';
import { InventoryMaterialRequest } from './helpers';
import { getInventoryMaterialCategories } from '../../../ConfigInventoryMaterials/subcomponents/CategoriesOptions/http';
import { getInventoryMaterialLocations } from '../../../ConfigInventoryMaterials/subcomponents/LocationsOptions/http';
import { getInventoryMaterialManufacturers } from '../../../ConfigInventoryMaterials/subcomponents/ManufacturersOptions/http';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { patchInventoryMaterial, postInventoryMaterial } from '../../../../http';
import { useApp } from '../../../../../../../../layout/App';
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import { useInventoryMaterials } from '../../../../InventoryMaterials';
import IcoBoxReady from '../../../../../../../../component/icon/box_ready';

const defaultOptions = require('../../../../../../../../component/form/select/options.json')

const strpHoldingInventoryMaterial = (instance=null) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    let [expiration_year, expiration_month, expiration_day] = regex.test(instance?.expiration_date)
        ? instance.expiration_date.split('-').map(value => Number(value))
        : ['', '', '']

    return {
        id: instance?.id || null,
        name: instance?.name || '',
        product_type: instance?.product_type || '',
        code: instance?.code || '',
        category: instance?.category?.id || '',
        location: instance?.location?.id || '',
        manufacturer: instance?.manufacturer?.id || '',
        presentation: instance?.presentation || '',
        batch_quantity: instance?.batch_quantity || 0,
        minimum_stock: instance?.minimum_stock || 0,
        expiration_notice: instance?.expiration_notice || 0,
        expiration_day: expiration_day ? `${expiration_day < 10 ? '0': ''}${expiration_day}` : '',
		expiration_month: expiration_month ? `${expiration_month < 10 ? '0': ''}${expiration_month}` : '',
		expiration_year: expiration_year ? `${expiration_year}` : '',
        is_a_free_sample: instance?.is_a_free_sample || false,
    }
}

export default function InsertInventoryMaterialsModal({ initHoldingInventoryMaterial=null, fetchMaterials= () => null }) {
    
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const [activeCategories, setActiveCategories] = useState([])
    const [activeLocations, setActiveLocations] = useState([])
    const [activeManufacturers, setActiveManufacturers] = useState([])
    const [holdingInventoryMaterial, setHoldingInventoryMaterial] = useState(strpHoldingInventoryMaterial(initHoldingInventoryMaterial))
    const [holdingInventoryMaterialErr, setHoldingInventoryMaterialErr] = useState(strpHoldingInventoryMaterial())
    const { currentHealthPlaceUser } = useApp()
    const { setModalInfo } = useInventoryMaterials()

    useEffect(() => {
        fetchActiveCategoriesOptions()
        fetchActiveLocationsOptions()
        fetchActiveManufacturersOptions()
    }, [])

    const fetchActiveCategoriesOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialCategories({ active: true, ...params })
            setActiveCategories(res.data.results)
        } catch (err) {
            setActiveCategories([])
            console.error('fetchActiveCategoriesOptions ~ ', err)
        }
    }

    const fetchActiveLocationsOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialLocations({ active: true, ...params })
            setActiveLocations(res.data.results)
        } catch (err) {
            setActiveLocations([])
            console.error('fetchActiveLocationsOptions ~ ', err)
        }
    }

    const fetchActiveManufacturersOptions = async (params={}) => {
        try {
            let res = await getInventoryMaterialManufacturers({ active: true, ...params })
            setActiveManufacturers(res.data.results)
        } catch (err) {
            setActiveManufacturers([])
            console.error('fetchActiveManufacturersOptions ~ ', err)
        }
    }
    
    const handleChange = (event) => {
        let value = event.target.value
        if (event.target.name === 'code') {
            value = value.toUpperCase()
        } else if ((event.target.name === 'batch_quantity' || event.target.name === 'minimum_stock') && value > 10000000) {
            value = 10000000
        } else if (event.target.name === 'expiration_notice' && value > 1095) {
            value = 1095
        } else if ((event.target.name === 'batch_quantity' || event.target.name === 'minimum_stock' || event.target.name === 'expiration_notice') && value < 0) {
            value = 0
        } else if (event.target.name === 'batch_quantity' || event.target.name === 'minimum_stock' || event.target.name === 'expiration_notice') {
            value = Number(value)
        } 
        setHoldingInventoryMaterial(prev => ({ ...prev, [event.target.name]: value }))
        setHoldingInventoryMaterialErr(prev => ({ ...prev, [event.target.name]: false }))
    }

    const handleSelect = (event) => {
        setHoldingInventoryMaterial(prev => ({ ...prev, [event.target.id]: event.target.selected }))

        let field = event.target.id
        if (field === 'expiration_day' || field === 'expiration_month' || field === 'expiration_year') {
            setHoldingInventoryMaterialErr(prev => ({ ...prev, 'expiration_day': false, 'expiration_month': false, 'expiration_year': false }))
        } else {
            setHoldingInventoryMaterialErr(prev => ({ ...prev, [event.target.id]: false }))
        }
    }

    const handleClearFields = () => {
        setHoldingInventoryMaterial(strpHoldingInventoryMaterial())
        setHoldingInventoryMaterialErr(strpHoldingInventoryMaterial())
    }

    const handleSubmit = async () => {
        const payload = new InventoryMaterialRequest({ ...holdingInventoryMaterial, health_place: currentHealthPlaceUser?.health_place?.id })

        if (!payload.isValid()) {
            let errors = payload.getErrors()
            if (Object.keys(errors).length) return setHoldingInventoryMaterialErr(errors)
        }

        dispatch(loading())
        try {
            if (payload?.id) {
                await patchInventoryMaterial(payload?.id, payload)
            } else {
                await postInventoryMaterial(payload)
            }
            fetchMaterials({ "offset": 0 })
            setHoldingInventoryMaterial(strpHoldingInventoryMaterial())
            dispatch(AddAlert('Estoque de Materiais', 'Material salvo com sucesso!', AlertType.SUCCESS))
            setModalInfo(MODAL_INIT_PROPS)
        } catch (err) {
            console.error('handleSubmit ~ ', err)
        }
        dispatch(loaded())
    }

    const handleSearchCategory = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
    
        searchTimeout.current = setTimeout(() => {
            fetchActiveCategoriesOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

    const handleSearchLocation = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
    
        searchTimeout.current = setTimeout(() => {
            fetchActiveLocationsOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

    const handleSearchManufacturer = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
    
        searchTimeout.current = setTimeout(() => {
            fetchActiveManufacturersOptions({ 'name__icontains': event.target.value })
        }, 400, event.target.value)
    }

    return <div className='InsertInventoryMaterialsModal'>
        <div className='InsertInventoryMaterialsModal-Subtitle'>
            <div><IcoBoxReady style={{ width: '32px', height: '32px' }} /></div>
            <b>Salvar Material</b>
        </div>
        <div className='InsertInventoryMaterialsModal-Form'>
            <div className='InsertInventoryMaterialsModal-Form-Body'>
                <div className='InsertInventoryMaterialsModal-Form-Body-2W InsertInventoryMaterialsModal-Input'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Nome</span>
                    <Input
                        name='name'
                        action={handleChange}
                        value={holdingInventoryMaterial.name}
                        errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.name }}
                        maxLength='100'
                    />
                </div>
                {
                    /*
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Select'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Tipo do Produto</span>
                    <NewSelect
                        id='product_type'
                        onSelect={handleSelect}
                        selected={holdingInventoryMaterial.product_type}
                        options={[]}
                        error={holdingInventoryMaterialErr.product_type}
                    />
                </div>
                     */
                }
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Input'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Código</span>
                    <Input
                        name='code'
                        action={handleChange}
                        value={holdingInventoryMaterial.code}
                        errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.code }}
                        maxLength='50'
                    />
                </div>
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Select'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Categoria</span>
                    <NewSelect 
                        id='category'
                        onSelect={handleSelect}
                        selected={holdingInventoryMaterial.category}
                        options={activeCategories}
                        optionStrKey='name'
                        error={holdingInventoryMaterialErr.category}
                        filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                            <Input
                                placeholder='Pesquisar por categoria'
                                action={handleSearchCategory}
                                actionFocus={()=> fetchActiveCategoriesOptions()}
                            />
                        </div>}
                    />
                </div>
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Select'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Localização</span>
                    <NewSelect 
                        id='location'
                        onSelect={handleSelect}
                        selected={holdingInventoryMaterial.location}
                        options={activeLocations}
                        optionStrKey='name'
                        error={holdingInventoryMaterialErr.location}
                        filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                            <Input
                                placeholder='Pesquisar por localização'
                                action={handleSearchLocation}
                                actionFocus={()=> fetchActiveLocationsOptions()}
                            />
                        </div>}
                    />
                </div>
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Select'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Fabricante</span>
                    <NewSelect 
                        id='manufacturer'
                        onSelect={handleSelect}
                        selected={holdingInventoryMaterial.manufacturer}
                        options={activeManufacturers}
                        optionStrKey='name'
                        error={holdingInventoryMaterialErr.manufacturer}
                        filterNode={<div className='InsertInventoryMaterialsModal-NewSelect'>
                            <Input
                                placeholder='Pesquisar por fabricante'
                                action={handleSearchManufacturer}
                                actionFocus={()=> fetchActiveManufacturersOptions()}
                            />
                        </div>}
                    />
                </div>
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Input'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Apresentação</span>
                    <Input
                        name='presentation'
                        action={handleChange}
                        value={holdingInventoryMaterial.presentation}
                        errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.presentation }}
                        maxLength='50'
                        placeholder='Ex.: Garrafa, pacote, etc.'
                    />
                </div>
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Select'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Quantidade (Lote)</span>
                    <Input
                        name='batch_quantity'
                        action={handleChange}
                        value={holdingInventoryMaterial.batch_quantity}
                        errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.batch_quantity }}
                        maxLength='4'
                        type='number'
						min='0'
						max='10000000'
                        placeholder='Ex.: 100, 150, 200'
                    />
                </div>
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Select'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Estoque mínimo para aviso</span>
                    <Input
                        name='minimum_stock'
                        action={handleChange}
                        value={holdingInventoryMaterial.minimum_stock}
                        errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.minimum_stock }}
                        maxLength='4'
                        type='number'
                        min='0'
                        max='10000000'
                        placeholder='Ex.: 10, 25, 50'
                    />
                </div>
                <div className='InsertInventoryMaterialsModal-Form-Body-1W InsertInventoryMaterialsModal-Input'>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Aviso de vencimento (Dias)</span>
                    <Input
                        name='expiration_notice'
                        action={handleChange}
                        value={holdingInventoryMaterial.expiration_notice}
                        errors={{ error: holdingInventoryMaterialErr, message: holdingInventoryMaterialErr.expiration_notice }}
                        maxLength='4'
                        type='number'
                        min='0'
                        max='1095'
                        placeholder='Ex.: 10, 25, 50'
                    />
                </div>
                <div>
                    <span><b className='InsertInventoryMaterialsModal-Asterisk'>*</b> Validade</span>
                    <div className='InsertInventoryMaterialsModal-Form-Body-ExpirationDate InsertInventoryMaterialsModal-Select'>
                        <NewSelect
                            id='expiration_day'
                            defaultText='Dia'
                            options={defaultOptions.days}
                            onSelect={handleSelect}
                            selected={holdingInventoryMaterial?.expiration_day}
                            error={holdingInventoryMaterialErr?.expiration_day}
                        />
                        <NewSelect
                            id='expiration_month'
                            defaultText='Mês'
                            options={defaultOptions.months}
                            onSelect={handleSelect}
                            selected={holdingInventoryMaterial?.expiration_month}
                            error={holdingInventoryMaterialErr?.expiration_month}
                        />
                        <NewSelect 
                            id='expiration_year'
                            defaultText='Ano'
                            options={getYearsRangeTodayToPlus50()}
                            onSelect={handleSelect}
                            selected={holdingInventoryMaterial?.expiration_year}
                            error={holdingInventoryMaterialErr?.expiration_year}
                        />
                    </div>
                </div>
            </div>
            <div className='InsertInventoryMaterialsModal-Form-ActionRow'>
                <div>
                    <NewCheckbox
                        state={[{ 
                            id: 'is_a_free_sample', 
                            name: 'Amostra Grátis', 
                            checked: holdingInventoryMaterial.is_a_free_sample 
                        }]}
                        defaultHandleCheckbox={false}
                        setState={() => setHoldingInventoryMaterial(prev => ({ ...prev, 'is_a_free_sample': !prev.is_a_free_sample }))}
                    />
                </div>
                <div className='InsertInventoryMaterialsModal-Form-ActionRow-BtnBox'>
                    <NewButton 
                        label='Limpar campos'
                        onClick={handleClearFields}
                        model={ButtonModel.SECONDARY}
                    />
                    <NewButton 
                        label='Salvar cadastro'
                        onClick={handleSubmit}
                    />

                </div>

            </div>
        </div>
    </div>
}