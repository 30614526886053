import React from 'react'

export function AgreementIcon({style={}}) {
    return <svg style={style} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
            <path d="M336 5109 c-109 -26 -215 -113 -265 -219 l-26 -55 0 -2275 0 -2275
            26 -55 c41 -85 99 -144 187 -187 l76 -38 1620 0 c1570 0 1623 1 1671 19 109
            41 193 124 237 233 l23 58 0 598 0 599 -28 24 c-34 29 -67 31 -98 5 l-24 -19
            -5 -599 c-5 -594 -5 -598 -27 -640 -27 -50 -85 -100 -133 -114 -23 -6 -571 -9
            -1632 -7 l-1596 3 -43 30 c-50 35 -84 82 -99 134 -14 50 -14 4412 0 4462 15
            52 49 99 99 134 l43 30 1596 3 c1061 2 1609 -1 1632 -7 48 -14 106 -64 133
            -114 22 -41 22 -50 25 -453 l3 -411 -601 -1392 -601 -1392 3 -363 3 -363 29
            -37 c32 -42 95 -76 141 -76 17 1 46 8 67 18 20 9 149 123 286 252 l250 235
            736 1705 c405 938 741 1711 745 1719 8 12 16 12 55 -3 25 -10 48 -21 51 -26 3
            -5 -125 -312 -285 -683 -159 -371 -290 -684 -290 -695 0 -10 9 -31 21 -46 25
            -31 79 -36 107 -8 27 27 632 1442 632 1478 0 41 -19 57 -126 103 -62 27 -90
            44 -92 57 -8 53 -15 65 -45 80 -17 9 -33 18 -35 19 -1 1 14 40 35 86 33 75 37
            93 37 169 1 70 -3 93 -23 134 -66 135 -214 203 -352 162 -112 -32 -172 -93
            -229 -228 -29 -69 -36 -78 -59 -78 -31 0 -101 -31 -119 -52 -7 -8 -50 -102
            -96 -208 l-84 -192 -4 228 c-4 207 -7 235 -27 287 -41 104 -124 189 -228 232
            -47 20 -78 20 -1653 22 -947 0 -1621 -3 -1644 -8z m4296 -166 c41 -25 71 -77
            72 -127 1 -42 -60 -199 -75 -194 -4 2 -66 28 -138 58 -148 62 -141 48 -85 167
            41 86 76 113 147 113 29 0 61 -7 79 -17z m-167 -420 c110 -47 203 -89 208 -93
            5 -4 -604 -1434 -617 -1447 -5 -6 -480 205 -483 214 -3 7 402 956 597 1401
            l19 43 38 -16 c21 -9 128 -55 238 -102z m-651 -1609 c93 -41 171 -76 174 -78
            4 -4 -774 -1815 -784 -1825 -9 -9 -490 203 -487 215 1 5 175 412 388 904 213
            492 389 900 392 907 5 12 17 8 317 -123z m-916 -1948 c106 -46 195 -86 198
            -88 6 -7 -374 -362 -392 -366 -12 -3 -14 39 -14 267 0 149 3 271 8 271 4 0 94
            -38 200 -84z"/>
            <path d="M1091 4672 c-70 -35 -76 -54 -79 -242 -4 -190 5 -226 62 -261 29 -18
            59 -19 518 -19 l487 0 20 26 c28 35 26 69 -4 99 l-24 25 -451 0 -450 0 0 115
            0 115 785 0 785 0 0 -115 0 -115 -180 0 c-179 0 -181 0 -205 -25 -32 -31 -33
            -74 -2 -103 22 -21 31 -22 239 -22 203 0 217 1 242 21 51 40 56 64 56 250 0
            155 -2 175 -20 204 -42 67 -10 65 -923 65 -763 0 -824 -1 -856 -18z"/>
            <path d="M689 3801 c-17 -13 -23 -29 -23 -56 0 -27 6 -43 23 -56 22 -18 67
            -19 1271 -19 1174 0 1249 1 1269 18 32 26 37 72 11 105 l-21 27 -1254 0
            c-1209 0 -1254 -1 -1276 -19z"/>
            <path d="M688 3313 c-9 -10 -19 -28 -22 -40 -8 -26 16 -78 39 -87 9 -3 576 -6
            1260 -6 1169 0 1244 1 1264 18 32 26 37 72 11 105 l-21 27 -1258 0 c-1185 0
            -1259 -1 -1273 -17z"/>
            <path d="M688 2823 c-9 -10 -19 -28 -22 -40 -8 -26 16 -78 39 -87 9 -3 451 -6
            981 -6 l965 0 24 25 c30 30 32 64 4 99 l-20 26 -978 0 c-919 0 -979 -1 -993
            -17z"/>
            <path d="M688 2333 c-9 -10 -19 -28 -22 -40 -8 -26 16 -78 39 -87 9 -3 246 -6
            526 -6 l510 0 24 25 c32 31 33 74 2 103 l-23 22 -521 0 c-483 0 -521 -1 -535
            -17z"/>
            <path d="M878 1051 c-86 -22 -168 -77 -221 -149 -78 -106 -84 -184 -17 -212
            45 -18 79 4 115 75 52 99 129 145 245 145 69 0 120 -23 207 -93 127 -100 201
            -130 328 -131 66 0 90 4 138 26 100 47 227 188 227 252 0 35 -41 76 -76 76
            -18 0 -37 -11 -58 -36 -129 -148 -151 -164 -228 -164 -79 0 -141 26 -230 96
            -97 77 -136 99 -215 119 -73 19 -131 18 -215 -4z"/>
        </g>
    </svg>
}
