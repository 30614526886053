import './PersonalDataLegalInformation.css'
import React from "react";
import { useConfiguration } from "../../../../../configuration"
import InfoLine from '../../../../../../../component/info-line/info-line';
import NewButton, { ButtonModel } from '../../../../../../../newcomponents/button/button';
import PersonalDataLegalInformationModal from './PersonalDataLegalInformationModal/PersonalDataLegalInformationModal';
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';

export default function PersonalDataLegalInformation() {
    const { setModalProps, personData } = useConfiguration()
    const getBirthday = () => {
        return `${personData.birthday_day}/${personData.birthday_month}/${personData.birthday_year}`
    }

    const getSex = {
        'M': "Masculino",
        "F": "Feminino"
    }

    const getGender = {
        'CISGENDER': 'Cisgênero',
        'TRANSGENDER': 'Transgênero',
        'NONBINARY': 'Não binário',
        'OTHERS': 'Outros'
    }

    return (
        <div>
            <div className='PersonalData-Header'>
                <span className='PersonalData-Header-Icon'>D</span>
                <p className='PersonalData-Header-Title'>Dados Pessoais</p>
            </div>
            <div className='PersonalData-info-content'>
                <HorizontalField
                    label='Nome'
                    content={personData.name || '-'}
                />
                <HorizontalField
                    label='CPF'
                    content={personData.cpf || '-'}
                />
                <HorizontalField
                    label='E-mail'
                    content={personData?.user?.email || '-'}
                />
                <HorizontalField
                    label='Telefone'
                    content={personData.contact_phone || '-'}
                />
				<HorizontalField
                    label='Data de nascimento'
                    content={getBirthday() || '-'}
                />
				<HorizontalField
                    label='Sexo'
                    content={getSex[personData.sex] || '-'}
                />
                <HorizontalField
                    label='Gênero'
                    content={getGender[personData.gender] || '-'}
                />
            </div>
            <div className='PersonalData-Edit'>
                <div
                    className='PersonalData-Edit-Btn'
                    onClick={() => setModalProps(prev => ({
                        ...prev,
                        open: true,
                        title: 'Dados pessoais',
                        content: <PersonalDataLegalInformationModal />,
                        dismissText: '',
                    }))}
                >
                    <b>Editar dados</b>
                </div>
            </div>
        </div>
    )
}