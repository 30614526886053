import { AddAlert, AlertType, Input } from "../../../../../../../../component";
import NewSelect from "../../../../../../../../newcomponents/form/select/select";
import NewButton, {
	ButtonModel,
} from '../../../../../../../../newcomponents/button/button'
import { useState } from "react";
import { MODAL_INIT_PROPS } from "../../../../../../../../newcomponents/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { useApp } from "../../../../../../../../layout/App";
import { loaded, loading } from "../../../../../../../../layout/redux/AppActions";
import { buildDate } from "../../../../../../../../utils/convertDate";
import { patchCouncil, postCouncil } from "../../../../../../http";
import { useConfiguration } from "../../../../../../configuration";

const strpCouncilData = (data) => {
	return {
		"id": data?.id || null,
		"council": data?.council || "",
		"nu_crm": data?.nu_crm || "",
		"uf_crm": data?.uf_crm || "",
		"person": data?.person || "",
		"registration_date": data?.registration_date || "",
		"specialties": data?.specialties || ""
	}
}

export default function EditCouncilModal({ initCouncilData, setSecondaryModalProps }){
    const [holdingCouncil, setHoldingCouncil] = useState(strpCouncilData(initCouncilData))
	const [holdingCouncilErr, setHoldingCouncilErr] = useState({})
    const { listas } = useSelector(state => state.appState)
    const { fetchUserCouncils, currentHealthPlaceUser } = useApp()
    const { setModalProps } = useConfiguration()
    const dispatch = useDispatch()

    const profile = currentHealthPlaceUser?.profile

    const councilNames = {
		DENTIST: 'CRO',
		PSYCHOLOGIST: 'CRP',
		NUTRITIONIST: 'CRN',
		NURSE: 'COREN',
	}

    const options = require('../../../../../../../../component/form/select/options.json')

    const handleChange = event => {
		setHoldingCouncilErr({})
		setHoldingCouncil(prev => ({
			...prev,
			[event.target.name]:
				event.target.value[0] === 't'
					? event.target.value
					: event.target.value.replace(/\D/g, ''),
		}))
	}

    const handleSelect = event => {
		setHoldingCouncilErr({})
		setHoldingCouncil(prev => ({
			...prev,
			[event.target.id]: event.target.selected,
		}))
	}

    const handleClose = () => {
		setSecondaryModalProps(MODAL_INIT_PROPS)
		setHoldingCouncil(initCouncilData)
	}

    const specialtiesOptions = {
		DENTIST: options.cro_specialties,
		PSYCHOLOGIST: options.crp_specialties,
		NUTRITIONIST: options.crn_specialties,
		NURSE: options.coren_specialties,
	}

    const handleSaveCouncil = async () => {
		let errors = {}
		if (!holdingCouncil.nu_crm) errors['nu_crm'] = 'Inscrição obrigatória'
		if (!holdingCouncil.uf_crm) errors['uf_crm'] = 'UF obrigatória'
		if (Object.keys(errors).length) return setHoldingCouncilErr(errors)

		let currentDate = new Date()
		let payload = {
			...holdingCouncil,
			'person': currentHealthPlaceUser?.user?.person?.id,
			'council': councilNames[profile],
			'registration_date': buildDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate())
		}

		dispatch(loading())
		try {
			if (holdingCouncil?.id) {
				await patchCouncil(holdingCouncil?.id, payload)
			} else {
				await postCouncil(payload)
			}
			fetchUserCouncils()
			dispatch([
				loaded(),
				AddAlert(
					`Cadastro de ${councilNames[profile]}`,
					`${councilNames[profile]} salvo com sucesso`,
					AlertType.SUCCESS
				),
			])
			setSecondaryModalProps(MODAL_INIT_PROPS)
            setModalProps(MODAL_INIT_PROPS)
		} catch (err) {
			console.error(err)
			dispatch([
				loaded(),
				AddAlert(
					`Cadastro de ${councilNames[profile]}`,
					`Falha ao salvar ${councilNames[profile]}, tente novamente!`,
					AlertType.ERROR
				),
			])
		}
	}

    return (<div>
        <div>
				<Input
					label='Inscrição'
					name='nu_crm'
					action={handleChange}
					value={holdingCouncil.nu_crm}
					maxLength='8'
					required={{
						erro: holdingCouncilErr,
						message: holdingCouncilErr.nu_crm,
					}}
					placeholder='ex: 123456'
				/>
				<NewSelect
					id='uf_crm'
					options={listas.estados}
					canBeEmpty={false}
					label='UF'
					optionRefKey='sigla'
					optionStrKey='nome'
					selected={holdingCouncil.uf_crm}
					onSelect={handleSelect}
					error={holdingCouncilErr?.uf_crm}
				/>
			</div>
			<NewSelect
				id='specialties'
				label='Especialidade'
				optionRefKey='name'
				options={specialtiesOptions[profile] || []}
				selected={holdingCouncil.specialties}
				onSelect={handleSelect}
			/>
			<div className='GenericCouncilManagerModal-SaveBtn'>
				<NewButton
					label='Fechar'
					onClick={handleClose}
					model={ButtonModel.SECONDARY}
				/>
				<NewButton
					label={`Salvar ${councilNames[profile]}`}
					onClick={handleSaveCouncil}
				/>
			</div>
    </div>)
}