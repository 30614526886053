export default function getInitials(str) {
    let words = str.split(' ');
    let initials = [];

    if(words[0]) {
        initials.push(words[0][0]);
    }

    if(words[1]) {
        initials.push(words[1][0]);
    }

    return initials.join('').toUpperCase();
}
