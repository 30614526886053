import './AssignScheduleToPatient.css'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { AddAlert, AlertType, Input } from '../../../../../../../../../../component'
import NewButton from '../../../../../../../../../../newcomponents/button/button'
import NewSelect from '../../../../../../../../../../newcomponents/form/select/select'
import { getHealthInsurances } from '../../../../../../../../../configuration/http'
import { uniqueIDs } from '../../../../../../../../../../utils/uniqueIDs'
import { getParams } from '../../../../../../../../../../utils'
import { MODAL_INIT_PROPS } from '../../../../../../../../../../newcomponents/modal/modal'
import SelectPatient from '../../../../../../../../../../component/SelectPatient/SelectPatient'
import { patchAttendance } from '../../../../../../../../http'
import { useBookingAppointmentCursor } from '../../../../BookingAppointmentCursor'
import { getSpecialties, postActionSchedule } from '../../../../../../../../../agenda/SelectScheduleHealthPlaceUserManager/http'
import { useApp } from '../../../../../../../../../../layout/App'

const { 
  DECISION_CHOICES, 
  RESULT_CHOICES, 
  CLASSIFICATION_CHOICES, 
  PRIORITY_CHOICES 
} = require('../../../../../../../../../../component/form/select/options.json')

const strpSelectedScheduling = (selectedScheduling=null) => {
  return {
    patient: selectedScheduling?.attendance?.person || {},
    start: selectedScheduling?.fields?.start || selectedScheduling?.start || '',
    end: selectedScheduling?.fields?.end || selectedScheduling?.end || '',
    data: selectedScheduling?.data || '',
    attendance: {
      id: selectedScheduling?.attendance?.id || '',
      result: selectedScheduling?.attendance?.result || 'NORMAL',
      classification: selectedScheduling?.attendance?.classification || 'ROUTINE',
      priority: selectedScheduling?.attendance?.priority || 'NO_PRIORITY',
      decision: selectedScheduling?.attendance?.decision || 'SCHEDULE',
      specialty: selectedScheduling?.attendance?.specialty || '',
      health_insurance: selectedScheduling?.attendance?.health_insurance || ''
    }
  }
}

export default function AssignScheduleToPatient({ initSchedulingInfo=null, setModalInfo=() => null }) {
    const dispatch = useDispatch()
    const searchHealthInsuranceTimeout = useRef(null)
    const searchSpecialtiesTimeout = useRef(null)
    const [selectedScheduling, setSelectedScheduling] = useState(strpSelectedScheduling(initSchedulingInfo))
    const [schedulingErr, setSchedulingErr] = useState({ "patient": false })
    const [healthInsurances, setHealthInsurances] = useState([])
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
    const { health_place_user__user__person, updateSchedule } = useBookingAppointmentCursor()
    const { currentHealthPlaceUser } = useApp()

    useEffect(() => {
        fetchSpecialtiesOptions({ offset: 0 })
        fetchHealthInsurances({ offset: 0 })
    }, [])

    const handleSelectPatient = (selectedPatient) => {
        setSchedulingErr(prev => ({ ...prev, "patient": false }))
        setSelectedScheduling(prev => ({ ...prev, "patient": selectedPatient }))
    }

    const handleSubmit = async () => {
        if (!selectedScheduling?.patient?.id) {
            setSchedulingErr(prev => ({ ...prev, "patient": true }))
            return
        }
    
        const dateFormat = selectedScheduling.data.split('/')
        let params = { date: `${dateFormat[2]}-${(dateFormat[1])}-${dateFormat[0]}` }

        if (selectedScheduling?.attendance?.id) {
        const payload = {
            "result": selectedScheduling?.attendance?.result,
            "classification": selectedScheduling?.attendance?.classification,
            "decision": selectedScheduling?.attendance?.decision,
            "priority": selectedScheduling?.attendance?.priority,
            "person": selectedScheduling.patient?.id,
            "specialty": selectedScheduling?.attendance?.specialty?.id || '',
            "health_insurance": selectedScheduling?.attendance?.health_insurance?.id || ''
        }
        
        await patchAttendance(selectedScheduling.attendance.id, payload)
            .then(() => {
                updateSchedule()
                dispatch(AddAlert('Agendar consulta', 'Consulta editada com sucesso!', AlertType.SUCCESS, 5000))
                setModalInfo(MODAL_INIT_PROPS)
            })
            .catch(error => {
                console.error(error)
                dispatch(AddAlert('Agendar consulta', error.response.data.detail, AlertType.ERROR, 5000))
            })
        } else {

        const payload = {
            "result": selectedScheduling?.attendance?.result,
            "classification": selectedScheduling?.attendance?.classification,
            "decision": selectedScheduling?.attendance?.decision,
            "priority": selectedScheduling?.attendance?.priority,
            "doctor": health_place_user__user__person,
            "health_place": currentHealthPlaceUser?.health_place?.id,
            "person": selectedScheduling.patient?.id,
            "start": selectedScheduling.start,
            "end": selectedScheduling.end,
            "specialty": selectedScheduling?.attendance?.specialty?.id,
            "health_insurance": selectedScheduling?.attendance?.health_insurance?.id
        }

        await postActionSchedule(payload)
            .then(() => {
                updateSchedule()
                dispatch(AddAlert('Agendar consulta', 'Consulta agenda com sucesso!', AlertType.SUCCESS, 5000))
                setModalInfo(MODAL_INIT_PROPS)
            })
            .catch(error => {
                console.error(error)
                dispatch(AddAlert('Agendar consulta', error.response.data.detail, AlertType.ERROR, 5000))
            })
        }
    }

    const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    const fetchHealthInsurances = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await getHealthInsurances(params)
			setHealthInsurances(res.data.results)
		} catch (err) {
			console.error('fetchHealthInsurances ~ ', err)
			dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
		}
	}
    
    const handleSearchHealthInsurance = (event) => {
        if (searchHealthInsuranceTimeout.current) clearTimeout(searchHealthInsuranceTimeout.current)

        searchHealthInsuranceTimeout.current = setTimeout(() => {
            fetchHealthInsurances({ 'name': event.target.value })
        }, 400, event.target.value)
    }

    const handleSearchSpecialties = (event) => {
        if (searchSpecialtiesTimeout.current) clearTimeout(searchSpecialtiesTimeout.current)

        searchSpecialtiesTimeout.current = setTimeout(() => {
            fetchSpecialtiesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    return (
    <div className='AssignScheduleToPatient'>
      <div className='AssignScheduleToPatient-Grid'>
        <SelectPatient 
          selectedPatient={selectedScheduling.patient} 
          handleSelectPatient={handleSelectPatient}
          inputErr={schedulingErr?.patient}
        />
        <div className='AssignScheduleToPatient-Separator' />
        <div>
          <div className='AssignScheduleToPatient-2FRRow'>
            <div>
              <NewSelect 
                label='Categoria *'
                onSelect={event => setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { ...prev.attendance, "classification": event.target.selected }
                  }))
                }
                canBeEmpty={false}
                options={CLASSIFICATION_CHOICES}
                selected={selectedScheduling?.attendance?.classification || ''}
              />
            </div>
            <div>
              <NewSelect 
                label='Status *'
                onSelect={event => setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { ...prev.attendance, "decision": event.target.selected } 
                  }))
                }
                canBeEmpty={false}
                options={DECISION_CHOICES}
                selected={selectedScheduling?.attendance?.decision || ''}
              />
            </div>
          </div>
          <div className='AssignScheduleToPatient-2FRRow'>
            <div>
              <NewSelect 
                label='Tipo *'
                onSelect={event => setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { ...prev.attendance, "result": event.target.selected } 
                  }))
                }
                canBeEmpty={false}
                options={RESULT_CHOICES}
                selected={selectedScheduling?.attendance?.result || ''}
              />
            </div>
            <div>
              <NewSelect 
                label='Prioridade'
                onSelect={event => setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { ...prev.attendance, "priority": event.target.selected } 
                  }))
                }
                canBeEmpty={false}
                options={PRIORITY_CHOICES}
                selected={selectedScheduling?.attendance?.priority || ''}
              />
            </div>
          </div>
          <div>
            <NewSelect 
              label='Convênio'
              onSelect={event => {
                  setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { 
                      ...prev.attendance, 
                      "health_insurance": prev?.attendance?.health_insurance?.id != event.target.selected 
                        ? healthInsurances?.find(({ id }) => id === event.target.selected)
                        : ''
                    } 
                  }))
                  fetchHealthInsurances({ offset: 0 })
                }
              }
              canBeEmpty={false}
              options={uniqueIDs(selectedScheduling?.attendance?.health_insurance, healthInsurances)}
              selected={selectedScheduling?.attendance?.health_insurance?.id || ''}
              filterNode={<div className='AssignScheduleToPatient-NewSelect'>
                <Input 
                    placeholder='Pesquisar por nome'
                    action={handleSearchHealthInsurance}
                    actionFocus={()=> fetchHealthInsurances()}
                />
              </div>
              }
            />
          </div>
          <div>
            <NewSelect 
              label='Especialidade'
              onSelect={event => {
                  setSelectedScheduling(prev => ({ 
                    ...prev, 
                    "attendance": { 
                      ...prev.attendance, 
                      "specialty": prev?.attendance?.specialty?.id != event.target.selected 
                        ? specialtiesOptions?.find(({ id }) => id === event.target.selected)
                        : ''
                    } 
                  }))
                  fetchSpecialtiesOptions({ offset: 0 })
                } 
              }              
              canBeEmpty={false}
              options={uniqueIDs(selectedScheduling?.attendance?.specialty, specialtiesOptions)}
              optionRefKey='id'
              optionStrKey='strf'
              selected={selectedScheduling?.attendance?.specialty?.id || ''}
              filterNode={<div className='AssignScheduleToPatient-NewSelect'>
                <Input 
                    placeholder='Pesquisar por nome'
                    action={handleSearchSpecialties}
                    actionFocus={()=> fetchSpecialtiesOptions()}
                />
              </div>
              }
            />
          </div>
        </div>
      </div>
      <div className='AssignScheduleToPatient-BtnBox'>
        <NewButton
          label='Salvar'
          onClick={handleSubmit}
        />
      </div>

    </div>
  )
}