import './HealthPlaceInstitutionalCoverageCities.css'
import React from "react";
import { useConfiguration } from '../../../../../configuration';
import HorizontalField from '../../../../../../../component/HorizontalField/HorizontalField';
import EditInstitutionalCoverageCitiesModal from './EditInstitutionalCoverageCitiesModal/EditInstitutionalCoverageCitiesModal';

export default function HealthPlaceInstitutionalCoverageCities() {
    const { institution, setModalProps } = useConfiguration()

    return <div className='HealthPlaceInstitutionalCoverageCities'>
        <div className='HealthPlaceInstitutionalCoverageCities-Header'>
            <div className='HealthPlaceInstitutionalCoverageCities-Header-Icon'>A</div>
            <div className='HealthPlaceInstitutionalCoverageCities-Header-Title'>Área de Abrangência (cidades)</div>
        </div>
        <div>
            {
                institution?.citys?.length
                ? institution?.citys.map(city_cursor => <HorizontalField label={`${city_cursor?.name}/${city_cursor?.state}`} content={'Habilitada'} />) 
                : <p>Nenhuma cidade habilitada</p>
            }
        </div>
        <div className='HealthPlaceInstitutionalCoverageCities-ActionRow'>
            <div 
                className='HealthPlaceInstitutionalCoverageCities-ActionRow-Btn' 
                onClick={() => setModalProps(prev => ({
                    ...prev,
                    open: true,
                    title: 'Editar Instituição',
                    content: <EditInstitutionalCoverageCitiesModal />,
                    dismissText: '',
                }))}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}

