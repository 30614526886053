import { userRoles } from "./userRoles"

export const stringifyCollaboratorOption = (instance) => {
    const str = instance?.user?.person?.name
      ? `${instance?.user?.person?.name}`
      //? `${instance?.user?.person?.sex === 'F' ? 'Dra ' : 'Dr '} ${instance?.user?.person?.name}`
      : `${instance?.user?.email} (cadastro incompleto)`

    const str_health_place = instance?.health_place?.name
      ? `| ${instance?.health_place?.name}`
      : null
  
    return {
      "id": instance?.user?.person?.id,
      "str": `${str} | ${userRoles?.[instance?.profile]?.title || '-'} ${str_health_place}`,
      "name": instance?.user?.person?.name,
      "sex": instance?.user?.person?.sex,
      "health_place_user__id": instance?.id
    }
  }