import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImagemCropper } from '../../../../../../../../component'
import NewButton, {
	ButtonModel,
} from '../../../../../../../../newcomponents/button/button'
import { useHealthPlaceForm } from '../SaveHealthPlaceModal'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import { postHealthPlaceBranch } from '../../../../../../http'
import replaceEmptyWithNull from '../../../../../../../../utils/replaceEmptyWithNull'
import {
	AddAlert,
	AlertType,
} from '../../../../../../../../component/alert/actions'
import { useApp } from '../../../../../../../../layout/App'

const parseHealthPlace = (healthPlace) => {
	return {
		address: { ...healthPlace.address, city: healthPlace.address.city.id },
		name: healthPlace.name,
		technical_responsible: healthPlace.technical_responsible.health_place_user__id,
		cnes: healthPlace.cnes,
		type: healthPlace?.type?.id,
		cnpj: healthPlace.cnpj.replace(/\D/g, ''),
		contact_whatsapp: healthPlace.contact_whatsapp.replace(/\D/g, ''),
		timetable: healthPlace.timetable,
		headquarter: healthPlace.headquarter || null,
	}
}

export default function HealthPlaceLogoData() {
	const dispatch = useDispatch()
	const { currentHealthPlaceUser } = useApp()
	const { setModalProps, fetchHealthPlaceBranches } = useConfiguration()
	const { timetableRef, base64AvatarRef, selectedHealthPlace } = useHealthPlaceForm()
	const [validacao, setValidacao] = useState({ base64_avatar: base64AvatarRef.current, })

	const handleChange = (event) => {
		base64AvatarRef.current = event.target.value
		setValidacao({ ...validacao, base64_avatar: base64AvatarRef.current })
	}

	const handleSubmit = async () => {
		const validation = Object.values(validacao) === null || undefined || ''

		if (validation) {
			dispatch(
				AddAlert('Erro na validação', 'Erro no tipo da imagem', AlertType.ERROR)
			)
			return
		}
		const payload = parseHealthPlace({
			...selectedHealthPlace,
			timetable: replaceEmptyWithNull(timetableRef.current),
			base64_avatar: base64AvatarRef.current,
			headquarter: currentHealthPlaceUser?.health_place?.id,
		})
		await postHealthPlaceBranch(payload)
			.then(() => {
				setModalProps(MODAL_INIT_PROPS)
				fetchHealthPlaceBranches()
			})
			.catch((err) => {
				console.error('Save health place error', err)
			})
	}

	return (
		<div className='HealthPlaceForm-Body HealthPlaceLogoData'>
			<div>
				<ImagemCropper
					name='base64_avatar'
					textBtn='Selecionar logo'
					value={base64AvatarRef.current}
					action={handleChange}
					config={{
						style: { height: 200, width: '100px' },
						cropBoxResizable: true,
						initialAspectRatio: 4 / 1,
					}}
				/>
			</div>
			<div>
				<div className='HealthPlaceForm-Btn-Box'>
					<NewButton
						model={ButtonModel.SECONDARY}
						label=' Fechar'
						onClick={() => setModalProps(MODAL_INIT_PROPS)}
					/>
					<NewButton
						label='Cadastrar'
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</div>
	)
}
