import './ThousandDaysSection.css'
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { strpPregnancyBackground } from './subsections/GestationSubSection/subcomponents/PregnancyRiskCalculator/utils';
import { strpPartnerPrenatal } from './subsections/GestationSubSection/subcomponents/PartnerPrenatal/utils';
import { getPregnancyAge, strpObstetricData } from './subsections/InitSubSection/utils';
import { AddAlert, AlertType } from "../../../../../../component";
import { strpPartograph } from "./subsections/PartographSubSection/subcomponents/Childbirth/utils";
import { strpImediatePostPartum } from "./subsections/PartographSubSection/subcomponents/ImediatePostPartum/utils";
import Tabs from '../../../../../../newcomponents/tabs/tabs';
import TabItem from '../../../../../../newcomponents/tabs/tab-item';
import GestationSubSection from './subsections/GestationSubSection/GestationSubSection';
import ChildbirthSubSection from './subsections/PartographSubSection/ChildbirthSubSection';
import InitSubSection from './subsections/InitSubSection/InitSubSection';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import { strpBirthForecast } from "./subsections/PartographSubSection/subcomponents/Birthforecast/utils";
import { BabyFeetIcon } from '../../../../../../component/icon/baby_feet';
import {
    getThousandDaysProjects,
    putBirthForescast,
    putImediatePostPartum,
    putObstetricData,
    putPartnerPrenatal,
    putPartograph,
    putPregnancyBackground
} from './http';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from '../../../../../../newcomponents/loading/loading';
import DropdownFlag from '../../../../../../component/form/dropdownFlag/dropdownFlag';


const ThousandDaysContext = React.createContext()

export function useThousandDays() {
    return useContext(ThousandDaysContext);
}

const init_birthforecast = strpBirthForecast()
const init_partograph_data = strpPartograph()
const init_obstetric_data = strpObstetricData()
const init_pregnancy_background = strpPregnancyBackground()
const init_imediate_post_partum = strpImediatePostPartum()
const init_partner_prenatal = strpPartnerPrenatal()

export default function ThousandDaysSection() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [selectedLanguage, setSelectedLanguage] = useState('pt-BR')
    const [holdingProject, setHoldingProject] = useState(null)
    const [obstetricData, setObstetricData] = useState(init_obstetric_data)
    const [birthforecast, setBirthforecast] = useState(init_birthforecast)
    const [pregnancyBackground, setPregnancyBackground] = useState(init_pregnancy_background)
    const [partograph, setPartograph] = useState(init_partograph_data)
    const [imediatePostPartum, setImediatePostPartum] = useState(init_imediate_post_partum)
    const [partnerPrenatal, setPartnerPrenatal] = useState(init_partner_prenatal)
    const [modalInfo, setModalInfo] = useState(MODAL_INIT_PROPS)
    const obstetric_ultrasonography_exam = holdingProject?.obstetric_ultrasonography_exams?.[0]

    const {
        Init,
        Pregnant,
        Prenatal,
        Childbirth,
        ThousandDays
    }  = require('../../../../../data/translation.json')[selectedLanguage]

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const primary_color = queryParams.get('primary_color');
    const secondary_color = queryParams.get('secondary_color');

    const root = document.documentElement
    if (primary_color) {
        root.style.setProperty('--primary-color', primary_color)
        root.style.setProperty('--primary-color-light', primary_color)
        root.style.setProperty('--primary-color-lighter', primary_color)
        root.style.setProperty('--primary-color-dark', primary_color)
        root.style.setProperty('--primary-color-darker', primary_color)
    }

    if (secondary_color) {
        root.style.setProperty('--primary-color-light-2', secondary_color)
    }



    const getPregnancyAgeAt = (date=null) => {
        return getPregnancyAge({
            'last_menstruation_day': obstetricData?.last_menstruation_day,
            'last_menstruation_month': obstetricData?.last_menstruation_month,
            'last_menstruation_year': obstetricData?.last_menstruation_year,
            'date': obstetric_ultrasonography_exam?.date,
            'ultrasound_weeks': obstetric_ultrasonography_exam?.ultrasound_weeks,
            'ultrasound_days': obstetric_ultrasonography_exam?.ultrasound_days,
            'day': date
        })
    }

    useEffect(() => {
        fetchThousandDaysProjects()
    }, [])

    const savePregnancyBackground = async () => {
        try {
            if (holdingProject?.id) {
                await putPregnancyBackground(pregnancyBackground, holdingProject.id)
            }
        } catch (err) {
            console.error('savePregnancyBackground', err)
        }
    }

    const savePartnerPrenatal = async () => {
        try {
            if (holdingProject?.id) {
                await putPartnerPrenatal(partnerPrenatal, holdingProject.id)
            }
        } catch (err) {
            console.error('savePartnerPrenatal', err)
        }
    }

    const saveImediatePostPartum  = async () => {
        try {
            if (holdingProject?.id) {
                await putImediatePostPartum(imediatePostPartum, holdingProject.id)
            }
        } catch (err) {
            console.error('saveImediatePostPartum', err)
        }
    }

    const savePartograph = async () => {
        try {
            if (holdingProject?.id) {
                await putPartograph(partograph, holdingProject.id)
            }
        } catch (err) {
            console.error('savePartograph', err)
        }
    }

    const saveObstetricData = async () => {
        try {
            if (holdingProject?.id) {
                await putObstetricData(obstetricData, holdingProject.id)
            }
        } catch (err) {
            console.error('saveObstetricData', err)
        }
    }

    const saveBirthForecast = async () => {
        try {
            if (holdingProject?.id) {
                await putBirthForescast(birthforecast, holdingProject.id)
            }
        } catch (err) {
            console.error('saveBirthForecast', err)
        }
    }

    useEffect(() => {
        setObstetricData(strpObstetricData(holdingProject?.obstetricdata))
        setPartograph(strpPartograph(holdingProject?.partograph))
        setImediatePostPartum(strpImediatePostPartum(holdingProject?.imediatepostpartum))
        setPregnancyBackground(strpPregnancyBackground(holdingProject?.pregnancy_background))
        setPartnerPrenatal(strpPartnerPrenatal(holdingProject?.partnerprenatal))
        setBirthforecast(strpBirthForecast(holdingProject?.birthforecast))
    }, [holdingProject?.id])

    const fetchThousandDaysProjects = async () => {
        try {
            let params = {}
            if (id) params['attendance'] = id
            
            const projects = await getThousandDaysProjects(params)
            const project = projects?.[0]
            setHoldingProject(project)
            const thousand_days_ok = new CustomEvent('thousand_days_ok', {
                detail: project
            })
            document.dispatchEvent(thousand_days_ok)
        } catch (err) {
            setHoldingProject(null)
            console.error(err)
            const thousand_days_error = new CustomEvent('thousand_days_error', {
                detail: err
            })
            document.dispatchEvent(thousand_days_error)
            dispatch(AddAlert('Projetos de paciente', 'Falha ao obter projetos', AlertType.ERROR))
        }
    }

    if (!holdingProject?.id) return <Loading />

    return <ThousandDaysContext.Provider
        value={{
            selectedLanguage,
            setSelectedLanguage,
            holdingProject,
            obstetricData,
            setObstetricData,
            pregnancyBackground,
            setPregnancyBackground,
            partnerPrenatal,
            setPartnerPrenatal,
            partograph,
            setPartograph,
            imediatePostPartum,
            setImediatePostPartum,
            birthforecast,
            setBirthforecast,
            modalInfo,
            setModalInfo,
            savePartograph,
            savePartnerPrenatal,
            saveImediatePostPartum,
            saveObstetricData,
            savePregnancyBackground,
            saveBirthForecast,
            getPregnancyAgeAt,
            fetchThousandDaysProjects
        }}
    >
        <div className='ThousandDays-Consultation-MainBoard'>
            <Modal {...modalInfo} dismissFn={() => setModalInfo(MODAL_INIT_PROPS)} />
            <div className='ThousandDays-Consultation-MainBoard-Header'>
                <div className='ThousandDays-Consultation-MainBoard-Header-PatientCard'>
                    <span>{Pregnant}</span><br/>
                    <b>{holdingProject.patient.name}</b>
                    <br/>
                </div>
                <DropdownFlag selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            </div>
            <div>
                <Tabs>
                    <TabItem id='obstetric_data' name={Init}>
                        <div className='ThousandDays-Consultation-TabWrapper'>
                            <InitSubSection />
                        </div>
                    </TabItem>
                    <TabItem id='pregnancy' name={Prenatal}>
                        <GestationSubSection />
                    </TabItem>
                    <TabItem id='childbirth' name={Childbirth}>
                        <ChildbirthSubSection />
                    </TabItem>
                </Tabs>
            </div>
            <div className='ThousandDays-Consultation-TabWrapper-Footer'>
                <div className='ThousandDays-Consultation-TabWrapper-Footer-1000days'>
                    <BabyFeetIcon style={{ width: '28px', fill: '#CED4DA', marginRight: '4px' }} /> {ThousandDays}
                </div>
            </div>
        </div>
    </ThousandDaysContext.Provider>
}


