import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddAlert, AlertType } from '../../../../../../../../component'
import { searchCep } from '../../../../../../../../layout/redux/AppActions'
import { setValidacao } from '../../../../../../../usuario/redux/UsuarioActions'
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button'
import { useHealthPlaceForm } from '../SaveHealthPlaceModal'
import { useConfiguration } from '../../../../../../configuration'
import { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal'
import FormAddress from './FormAddress'


export default function HealthPlaceAddressData() {
	const dispatch = useDispatch()
	const { setModalProps } = useConfiguration()
	const { setSelectedHealthPlace, selectedHealthPlace, onNext } = useHealthPlaceForm()
	const { validacao } = useSelector((state) => state.usuarioState)

	useEffect(() => {
		return () => dispatch(setValidacao())
		// eslint-disable-next-line
	}, [])

	const handleChange = (event) => {
		const { name, value } = event.target
		setSelectedHealthPlace((prev) => ({
			...prev,
			address: { ...prev.address, [name]: name === 'city' ? value.id : value },
		}))
	}

	const handleNext = () => {
		const validation = Object.values(validacao).some(
			(valor) => valor !== false && valor !== undefined
		)
		if (validation) {
			dispatch(AddAlert('Erro na validação','Campos obrigatórios vazios ou valor inválido',AlertType.ERROR))
			return
		}
		onNext()
	}

	const handleSearchCep = (e) => {
		let cep = Promise.resolve({
			then: function (onFulfill, onReject) {
				onFulfill(searchCep(e))
				onReject(searchCep(e))
			},
		})

		cep.then(function (v) {
			if (v.error) {
				dispatch(
					AddAlert('Consulta CEP', 'CEP não encontrado', AlertType.ERROR)
				)
			} else {
				setSelectedHealthPlace((prev) => ({
					...prev,
					address: {
						...prev.address,
						...v,
						zip: v.zip.replace(/\D/g, ''),
					},
				}))
			}
		})
	}

	return (
		<div className='HealthPlaceForm-Body HealthPlaceAddressData'>
			<FormAddress
				address={selectedHealthPlace.address}
				changeDados={handleChange}
				searchCep={handleSearchCep}
				validacao={validacao}
			/>
			<div>
				<div className='HealthPlaceForm-Btn-Box'>
					<NewButton
						model={ButtonModel.SECONDARY}
						label='Fechar'
						onClick={() => setModalProps(MODAL_INIT_PROPS)}
					/>
					<NewButton
						model={ButtonModel.PRIMARY}
						label=' Avançar'
						onClick={handleNext}
					/>
				</div>
			</div>
		</div>
	)
}
