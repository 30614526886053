import './AvailableScheduleMenu.css'
import React, { useState } from "react";
import { IcoBin, MaskDataHora } from '../../../../../../../../../../../../component';
import IcoQuickly from '../../../../../../../../../../../../component/icon/quickly';
import IcoBlockCalendar from '../../../../../../../../../../../../component/icon/calendar_block';
import { NewPatientIcon } from '../../../../../../../../../../../../component/icon/newPatient';
import { useBookingAppointmentCursor } from '../../../../../../BookingAppointmentCursor';
import AssignScheduleToPatient from '../../../AssignScheduleToPatient/AssignScheduleToPatient';
import DeleteScheduleSlot from '../../../DeleteScheduleSlot/DeleteScheduleSlot';
import MarkTimeAsUnavailable from '../../../MarkTimeAsUnavailable/MarkTimeAsUnavailable';

export default function AvailableScheduleMenu({ appointment={} }) {
    const [isOpen, setIsOpen] = useState(false);
    const { setModalInfo } = useBookingAppointmentCursor()
    const startHourTime = MaskDataHora(appointment?.fields?.start || appointment?.start, 'DD/MMM/AAAA', '-');
    const endHourTime = MaskDataHora(appointment?.fields?.end || appointment?.end, 'DD/MMM/AAAA', '-');

    const openAssignScheduleToPatient = () => {
      const start = appointment?.fields?.start?.slice(11,16) || appointment?.start?.slice(11,16) || '-'
      const end = appointment?.fields?.end?.slice(11,16) || appointment?.end?.slice(11,16) || '-'

      setModalInfo(prev => ({ 
          ...prev, 
          open: true,
          title: `Editar horário | ${startHourTime?.data} | ${start} - ${end}`,
          content: <AssignScheduleToPatient initSchedulingInfo={appointment} setModalInfo={setModalInfo} />
      }))
    }

    const openRemoveSchedule = () => {
      setModalInfo(prev => ({
          ...prev,
          open: true,
          title: 'Remover Horário(s)',
          content: <DeleteScheduleSlot appointments={[appointment]} />
      }))
    }

    const handleBlockSchedule = async () => {
      setModalInfo(prev => ({
        ...prev,
        open: true,
        title: 'Bloquear Horário(s)',
        content: <MarkTimeAsUnavailable appointments={[appointment]} />
      }))
    }

    const dropdownOptions = [
      { 
        title: 'Adicionar Paciente', 
        Icon: <NewPatientIcon style={{ width: '14px', height: '14px' }} />,
        action: openAssignScheduleToPatient,
      },
      { 
        title: 'Excluir Horário',
        Icon: <IcoBin style={{ width: '14px', height: '14px' }} className='' />,
        action: openRemoveSchedule
      },
      { 
        title: 'Bloquear Horário', 
        Icon: <IcoBlockCalendar style={{ width: '14px', height: '14px' }} className='' />,
        action: handleBlockSchedule
      },
    ]

    return <div 
      className='AvailableScheduleMenu'
      onMouseEnter={() => setIsOpen(true)} 
      onMouseLeave={() => setIsOpen(false)}
    >
      <div>
        <div>
          <strong>Disponível</strong>
        </div>
        <div className='AvailableScheduleMenu-HourTime'>
          <IcoQuickly style={{ fontSize: '16px' }} /> 
          <span style={{ fontSize: '12px' }}>{startHourTime?.hora?.slice(0,5) || '-'} | {endHourTime?.hora?.slice(0,5) || '-'}</span>
        </div>
      </div>
      <div className='AvailableScheduleMenu-Dropdown'>
          {isOpen && (
            <div className="AvailableScheduleMenu-Dropdown-content">
              {dropdownOptions.map((option, index) => (
                <div
                  key={index}
                  title={option.title}
                  onClick={option.action} 
                  className="AvailableScheduleMenu-Dropdown-item"
                >
                  {option.Icon} {option.label}
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  }