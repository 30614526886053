import './NewEditRoutine.css'
import React, { useState } from "react";
import { AddAlert, AlertType, Input, RadioButton } from "../../../../../../component";
import { useDispatch, useSelector } from "react-redux";
import NewButton, { ButtonModel } from '../../../../../../newcomponents/button/button';
import { isValidEmail } from '../../../../../../validators';
import { loaded, loading } from '../../../../../../layout/redux/AppActions';
import { MODAL_INIT_PROPS } from '../../../../../../newcomponents/modal/modal';
import { patchReportsRoutine, postReportsRoutine } from '../../../../http';
import { buildDate, formatDateWithTimezone } from '../../../../../../utils/convertDate';
import { useApp } from '../../../../../../layout/App';
import { INIT_FILTER_REPORTS } from '../../ReportsPage';
import joinNames from '../../../../../../utils/joinNames';

const defaultOptions = require('../../../../../../component/form/select/options.json')

export const getLabel = (id) => {
    switch(id) {
        case 'DAILY':
            return 'Relatório enviado todo dia seguinte'
        case 'WEEKLY':
            return 'Relatório enviado toda segunda-feira'
        case 'MONTHLY':
            return 'Relatório enviado toda primeira segunda-feira do mês'
        default:
            return ''
    }
}

function strpReport(routine=null) {
    return {
        id: routine?.id,
        periodicity: routine?.periodicity || 'DAILY', 
        reports_email: routine?.reports_email || '', 
        description: routine?.description || '' 
    }
}

function buildParams(filters) {
    //const start__gte = buildDate(filters?.year_start, filters?.month_start, filters?.day_start)
    //const start__lte = buildDate(filters?.year_end, filters?.month_end, filters?.day_end)

	const params = {
        doctor__in: filters?.doctor__in,
        decision__in: filters?.decision__in,
        result__in: filters?.result__in,
        classification__in: filters?.classification__in,
        priority__in: filters?.priority__in,
        specialty__in: filters?.specialty__in,
        health_insurance__in: filters?.health_insurance__in,
		person__address__city__in: filters?.person__address__city__in,
        //start__gte: formatDateWithTimezone(start__gte, '00', '00'),
        //start__lte: formatDateWithTimezone(start__lte, '23', '59'),
        related_diseases_and_problems__code__in: filters?.related_diseases_and_problems__code__in || [] 
    }

    return params
}

function isFilterEmpty(selectionObject) {
    for (let key in selectionObject) {
        if (Array.isArray(selectionObject[key]) && selectionObject[key].length > 0) {
            return false;
        } else if (typeof selectionObject[key] === 'string' && selectionObject[key].trim() !== '') {
            return false;
        }
    }
    return true;
}

export default function NewEditRoutineModal({ initRoutine=null, fetchRoutines=() => null, setModalInfo=() => null, setFilters=() => null }) {
    const dispatch = useDispatch()
    const [report, setReport] = useState(strpReport(initRoutine))
    const [errors, setErrors] = useState({ reports_email: false, description: false })
    const { currentHealthPlaceUser } = useApp()
    
    const appointment_params = buildParams(initRoutine?.appointment_params)

    const handleChange = (event) => {
        setErrors({ reports_email: false, description: false })
        setReport(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleSubmit = async () => {
        if (!report?.description) return setErrors(prev => ({ ...prev, description: true }))
        if (!isValidEmail(report.reports_email)) return setErrors(prev => ({ ...prev, reports_email: true }))
        const payload = {
            health_place_user: currentHealthPlaceUser?.id,
            reports_periodicity: report.periodicity,
            reports_email: report.reports_email,
            description: report.description,
            appointment_params: buildParams(appointment_params),
        }
        dispatch(loading())
        
        if (report?.id) {
            patchReportsRoutine(report?.id, payload)
                .then(() => {
                    fetchRoutines()
                    dispatch([loaded(), AddAlert('Salvar rotina', 'Rotina salva com sucesso', AlertType.SUCCESS)])
                    setModalInfo(MODAL_INIT_PROPS)
                    setFilters(INIT_FILTER_REPORTS)
                })
                .catch(err => {
                    console.error('NewEditRoutine ~ handleSubmit ~ ', err)
                    dispatch([loaded(), AddAlert('Salvar rotina', 'Falha ao salvar rotina', AlertType.ERROR)])
                })
        } else {
            postReportsRoutine(payload)
                .then(() => {
                    fetchRoutines()
                    dispatch([loaded(), AddAlert('Salvar rotina', 'Rotina salva com sucesso', AlertType.SUCCESS)])
                    setModalInfo(MODAL_INIT_PROPS)
                    setFilters(INIT_FILTER_REPORTS)
                })
                .catch(err => {
                    console.error('NewEditRoutine ~ handleSubmit ~ ', err)
                    dispatch([loaded(), AddAlert('Salvar rotina', 'Falha ao salvar rotina', AlertType.ERROR)])
                })
        }


    }

    return <div className='NewEditRoutine'>
        <div>
            {
                !isFilterEmpty(appointment_params)
                ? <p className='NewEditRoutine-Title'>Filtros utilizados</p>
                : null
            }
            <div  className='NewEditRoutine-ActiveFilters'>
                {
                    appointment_params?.doctor__in?.length
                    ? <span><b>Profissionais: </b> {joinNames(appointment_params.doctor__in?.map(({name}) => name))}</span>
                    : null
                }
                {
                    appointment_params?.specialty__in?.length
                    ? <span><b>Especialidade: </b> {joinNames(appointment_params.specialty__in?.map(({name}) => name))}</span>
                    : null
                }
                {
                    appointment_params?.health_insurance__in?.length
                    ? <span><b>Convênio: </b> {joinNames(appointment_params.health_insurance__in?.map(({name}) => name))}</span>
                    : null
                }
                {
                    appointment_params?.classification__in?.length
                    ? <span><b>Categoria: </b> {joinNames(appointment_params.classification__in?.map(({name}) => name))}</span>
                    : null
                }
                {
                    appointment_params?.decision__in?.length
                    ? <span><b>Status: </b> {joinNames(appointment_params.decision__in?.map(({name}) => name))}</span>
                    : null
                }
                {
                    appointment_params?.priority__in?.length
                    ? <span><b>Prioridade: </b> {joinNames(appointment_params.priority__in?.map(({name}) => name))}</span>
                    : null
                }
                {
                    appointment_params?.result__in?.length
                    ? <span><b>Tipo: </b> {joinNames(appointment_params.result__in?.map(({name}) => name))}</span>
                    : null
                }
                {
                    appointment_params?.person__address__city__in?.length
                    ? <span><b>Residência do Paciente: </b> {joinNames(appointment_params.person__address__city__in?.map(({name, state}) => `${name}/${state}`))}</span>
                    : null
                }
                {
                    appointment_params?.related_diseases_and_problems__code__in?.length
                    ? <span><b>CIDs: </b> {appointment_params.related_diseases_and_problems__code__in.map((({code}) => code)).join(', ')}</span>
                    : null
                }
            </div>
        </div>
        <div className='NewEditRoutine-Grid'>
            <RadioButton
                label={getLabel(report.periodicity)}
                checked={report.periodicity}
                options={defaultOptions.PERIODICITY_CHOICES}
                action={event => setReport(prev => ({ ...prev, periodicity: event.target.value.id }))}
            />
            <Input 
                name='description'
                label='Título'
                value={report.description}
                action={handleChange}
                errors={{ error: errors, message: errors?.description }}
            />
            <Input
                label='E-mail'
                name='reports_email'
                value={report.reports_email}
                action={handleChange}
                errors={{ error: errors, message: errors?.reports_email }}
            />
        </div>
        <div className='NewEditRoutine-BtnBox'>
            <NewButton 
                label='Salvar'
                onClick={handleSubmit}
                model={ButtonModel.SECONDARY}
            />
        </div>
    </div>
}