import './NewDocumentModal.css'
import React, { useState } from "react"
import { AttachIcon } from '../../../../../../../../../../../../component/icon/attach'
import { AgreementIcon } from '../../../../../../../../../../../../component/icon/agreement'
import SaveDocumentSection from './subcomponents/SaveDocumentSection/SaveDocumentSection'
import AttachDocumentSection from './subcomponents/AttachDocumentSection/AttachDocumentSection'

export default function NewDocumentModal({ person=null, attendance=null, setModalInfo=() => null }) {
    const [openSection, setOpenSection] = useState(attendance ? 'SaveDocumentSection' : 'AttachDocumentSection')

    const sections = {
        'SaveDocumentSection': <SaveDocumentSection
            person={person}
            attendance={attendance}
            setModalInfo={setModalInfo}
        />,
        'AttachDocumentSection': <AttachDocumentSection
            person={person}
            attendance={attendance} 
            setModalInfo={setModalInfo}
        />
    }
    
    return <div className="NewDocumentModal">
        <div>
            {   
                attendance
                ? <div 
                    className={`NewDocumentModal-SideLeft-Btn ${openSection === 'SaveDocumentSection' ? 'active' : ''}`} 
                    onClick={() => setOpenSection('SaveDocumentSection')}
                >
                    <AgreementIcon style={{ width: '20px', marginRight: '12px'}} />  Novo documento
                </div>
                : null
            }
            
            <div 
                className={`NewDocumentModal-SideLeft-Btn ${openSection === 'AttachDocumentSection' ? 'active' : ''}`} 
                onClick={() => setOpenSection('AttachDocumentSection')}
            >
                <AttachIcon style={{ width: '20px', marginRight: '12px'}} /> Anexar
            </div>
        </div>
        <div className='NewDocumentModal-Separator' />
        <div className='NewDocumentModal-OpenSection'>
            { sections[openSection] }
        </div>
    </div>
}
