import { getParams } from '../../utils'
import api from '../../helpers/api'

export const getCollaborators = async (params={}) => {
    params = { ...params}
    return api.get(`health_place_user/user/${getParams(params)}`)
}

export const associateCollaborator = async (payload={}) => {
    return api.post(`health_place_user/associate/user/`, payload)
}

export const getCollaboratorById = async (id) => {
    return api.get(`health_place_user/user/${id}/`)
}