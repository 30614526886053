import React from 'react'
import NewButton, { ButtonModel } from '../../newcomponents/button/button'

import './modal.css'

export const MODAL_INIT_PROPS = { open: false, title: '', content: '', confirmText: '' }

export default function Modal({
    open,
    title,
    content,
    dismissFn,
    dismissText,
    confirmFn,
    confirmText,
    disableConfirm,
    pages=[],
    selectedPage='',
    dismissColor = 'SECONDARY',
    confirmColor = 'PRIMARY'
}) {
    pages = pages.map(page => {
        if (!selectedPage) return { ...page, status: '' }
        if (page.id !== selectedPage) return  { ...page, status: 'completed' }
        selectedPage = ''
        return { ...page, status: 'active' }
    })

    if(!open) return null

    return <div className='modal-overlay'>
        <div className='modal'>
            <div className='modal-header'>
                <b>{title}</b>
                <button 
                    onClick={(event) => dismissFn(event, false)}
                    className='modal-header-btn-close' 
                    type="button"
                >
                    &times;
                </button>
            </div>
            { !!pages.length && <div className='modal-progress-tracker-box'>
                    <ol class="modal-progress-tracker">
                    {
                        pages.map(page => <li className={`modal-step ${page.status}`} ><span class="modal-step-name">{page.label}</span></li>)
                    }
                    </ol>
                </div>
            }
            { <div className='modal-content'>{content}</div>}
            <div className='modal-buttons btn-box'>
                {dismissText &&
                    <NewButton
                        label={dismissText}
                        model={ButtonModel[dismissColor]}
                        onClick={(event) => dismissFn(event, false)}
                    />
                }
                {confirmText &&
                    <NewButton
                        label={confirmText}
                        model={ButtonModel[confirmColor]}
                        onClick={confirmFn ? confirmFn : (event) => dismissFn(event, true)}
                        disabled={disableConfirm}
                    />
                }
            </div>
        </div>
    </div>


}