import './WaitingRoomAttendancesFilters.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { AddAlert, AlertType, Input, MaskDataHora } from '../../../../../../component';
import NewSelect from '../../../../../../newcomponents/form/select/select';
import { uniqueIDs } from '../../../../../../utils/uniqueIDs';
import { stringifyCollaboratorOption } from '../../../../../../utils/stringifyCollaboratorOption';
import { getCollaborators } from '../../../../../collaborators/http';
import { getParams } from '../../../../../../utils';
import { getHealthInsurances } from '../../../../../configuration/http';
import NewAppointmentModal from '../../../AttendanceHistory/subcomponents/NewAppointmentModal/NewAppointmentModal';
import { PatientIcon } from '../../../../../../component/icon/patient';
import { getSpecialties } from '../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import { useApp } from '../../../../../../layout/App';

const defaultOptions = require('../../../../../../component/form/select/options.json')

export default function WaitingRoomAttendancesFilters({ filtersRef=null, fetchAttendancesHistory=() => null, setModalProps=() => null }) {
    const dispatch = useDispatch()
	const searchTimeOut = useRef(null)
	const searchCollaboratorsTimeout = useRef(null)
	const searchHealthInsuranceTimeout = useRef(null)
	const searchSpecialtiesTimeout = useRef(null)
	const { currentHealthPlaceUser } = useApp()
	const [collaborators, setCollaborators] = useState([])
	const [healthInsurances, setHealthInsurances] = useState([])
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
	const healthPlace = currentHealthPlaceUser?.health_place
	
	useEffect(() => {
        fetchCollaborators({ offset: 0 })
		fetchHealthInsurances({ offset: 0 })
		fetchSpecialtiesOptions({ offset: 0 })
    }, [])

    const fetchCollaborators = async params => {
		await getCollaborators({
			...params,
			health_place__id: healthPlace?.id,
			limit: 500,
			profile__in: 'DOCTOR%2CDENTIST%2CPSYCHOLOGIST%2CNUTRITIONIST%2CNURSE',
			has_person: true
		})
			.then(res => setCollaborators(res.data.results.map(instance => stringifyCollaboratorOption(instance))))
			.catch(err => {
				console.error('fetchCollaborators', err)
				setCollaborators([])
			})
	}
	
	const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 50 }

        try {
            let res = await getSpecialties(params)

            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

	const fetchHealthInsurances = async (params={}) => {
		params = getParams({ ...params, limit: 50 })

		try {
			let res = await getHealthInsurances(params)
			setHealthInsurances(res.data.results)
		} catch (err) {
			console.error('fetchHealthInsurances ~ ', err)
			dispatch(AddAlert('Planos & convênios', 'Erro ao listar planos e convênios', AlertType.ERROR))
		}
	}

    const getDate = number => {
		const year = new Date().getFullYear() - number
		const month = new Date().getMonth()
		const day = new Date().getDate()

		const date = `${year}-${month}-${day < 10 ? `0${day}` : day}`

		return date
	}

    const handleSearch = async target => {
		filtersRef.current = { ...filtersRef.current, [target.name]: target.value }
		
		try {
			searchTimeOut.current && clearTimeout(searchTimeOut.current)
			searchTimeOut.current = setTimeout(() => {
				fetchAttendancesHistory({ offset: 0 })
			}, 300)
		} catch (err) {
			console.error('handleSearch', err)
		}
	}

	const handleSearchCollaborators = event => {
		if (searchCollaboratorsTimeout.current)
			clearTimeout(searchCollaboratorsTimeout.current)

		searchCollaboratorsTimeout.current = setTimeout(
			() => {
				fetchCollaborators({ name_cpf_or_email: event.target.value })
			},
			400,
			event.target.value
		)
	}

	const handleSearchHealthInsurance = (event) => {
		if (searchHealthInsuranceTimeout.current) clearTimeout(searchHealthInsuranceTimeout.current)
	
		searchHealthInsuranceTimeout.current = setTimeout(() => {
			fetchHealthInsurances({ 'name': event.target.value })
		}, 400, event.target.value)
	}

	const handleSearchSpecialties = (event) => {
        if (searchSpecialtiesTimeout.current) clearTimeout(searchSpecialtiesTimeout.current)

        searchSpecialtiesTimeout.current = setTimeout(() => {
            fetchSpecialtiesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    return <div>
        <section className='WaitingRoomAttendancesFilters-SideBarLeft'>
			<div className='WaitingRoomAttendancesFilters-ActionRow'>
				<div
					onClick={() =>
						setModalProps({
							open: true,
							title: `Novo atendimento | ${MaskDataHora(new Date())?.data} | ${MaskDataHora(new Date())?.hora}`,
							content: <NewAppointmentModal
								fetchAppointments={fetchAttendancesHistory}
								setModalInfo={setModalProps} 
							/>
						})
					}
					title='Novo atendimento'
				>
					<PatientIcon />
				</div>
			</div>
			<div className='WaitingRoomAttendancesFilters-Separator' />
			<div>
				<Input
					label='Paciente'
					name='name_or_cpf'
					action={event => handleSearch(event.target)}
					autoComplete='false'
					placeholder='Filtrar por Nome | CPF | RG'
				/>
				<div>
					<NewSelect
						id='doctor__id'
						label='Profissional'
						options={collaborators}
						onSelect={event => {
							handleSearch({ name: 'doctor__id', value: event.target.selected })
							fetchCollaborators({ offset: 0 })
						}}						
						optionStrKey='str'
						selected={filtersRef.current.doctor__id || ''}
						filterNode={
							<div className='ManageTeamEdgesModal-NewSelect'>
								<Input
									placeholder='Pesquisar por e-mail, nome ou cpf'
									action={handleSearchCollaborators}
									actionFocus={()=> fetchCollaborators()}
								/>
							</div>
						}
					/>
				</div>
				<NewSelect 
                    label='Especialidade'
					onSelect={event => {
						handleSearch({ name: 'specialty', value: event.target.selected })
						fetchSpecialtiesOptions({ offset: 0 })
					}}
                    options={uniqueIDs(filtersRef.current?.specialty?.id, specialtiesOptions)}
                    optionRefKey='id'
                    optionStrKey='strf'
                    selected={filtersRef.current?.specialty || ''}
                    filterNode={<div className='NewAppointmentModal-NewSelect'>
                        <Input 
                            placeholder='Pesquisar por nome'
                            action={handleSearchSpecialties}
							actionFocus={()=> fetchSpecialtiesOptions()}
                        />
                    </div>
                    }
                />
                <NewSelect 
                    label='Convênio'
					onSelect={event => {
						handleSearch({ name: 'health_insurance', value: event.target.selected })
						fetchHealthInsurances({ offset: 0 })
					}}
                    options={uniqueIDs(filtersRef.current?.health_insurance?.id, healthInsurances)}
                    selected={filtersRef.current?.health_insurance || ''}
                    filterNode={<div className='NewAppointmentModal-NewSelect'>
                        <Input 
                            placeholder='Pesquisar por nome'
                            action={handleSearchHealthInsurance}
							actionFocus={()=>fetchHealthInsurances()}
                        />
                    </div>
                    }
                />
				<NewSelect
					label='Categoria'
					name='classification'
					onSelect={event => handleSearch({ name: 'classification', value: event.target.selected })}
					options={defaultOptions.CLASSIFICATION_CHOICES}
					selected={filtersRef.current.classification || ''}
				/>
				<NewSelect
					label='Status'
					name='decision'
					onSelect={event => handleSearch({ name: 'decision', value: event.target.selected })}
					options={defaultOptions.DECISION_CHOICES}
					selected={filtersRef.current.decision || ''}
				/>
				<NewSelect
					label='Prioridade'
					name='decision'
					onSelect={event => handleSearch({ name: 'priority', value: event.target.selected })}
					options={defaultOptions.PRIORITY_CHOICES}
					selected={filtersRef.current.priority || ''}
				/>
				<NewSelect
					id='result'
					label='Tipo'
					onSelect={event => handleSearch({ name: 'result', value: event.target.selected })}
					options={defaultOptions.RESULT_CHOICES}
					selected={filtersRef.current.result || ''}
				/>
			</div>
			<div>
				<b>Idade</b>
				<div className='WaitingRoomAttendancesFilters-row'>
					<Input
						className='WaitingRoomAttendancesFilters-input'
						name='birthday-start'
						autoComplete='false'
						label='De'
						type='number'
						min='0'
						max='125'
						action={event => {
							if (event.target.value) {
								const age = Math.abs(event.target.value).toString().substring(2, 0)

								handleSearch({ name: 'person__birthday__lte', value: getDate(age) })
							} else {
								handleSearch({ name: 'person__birthday__lte', value: '' })
							}
						}}
					/>
					<Input
						name='birthday-end'
						autoComplete='false'
						label='Até'
						type='number'
						min='0'
						max='125'
						action={event => {
							if (event.target.value) {
								const age = Math.abs(event.target.value).toString().substring(2, 0)

								handleSearch({ name: 'person__birthday__gte', value: getDate(age) })
							} else {
								handleSearch({ name: 'person__birthday__gte', value: '' })
							}
						}}
					/>
				</div>
			</div>
		</section>
    </div>
}