import './AttendanceUnit.css'
import React from "react";
import { useHistory } from 'react-router-dom'
import { MaskDataHora } from '../../../../../../component';
import InfoLine from '../../../../../../component/info-line/info-line';
import NewAppointmentModal from '../NewAppointmentModal/NewAppointmentModal';
import { useApp } from '../../../../../../layout/App';


const { DECISION_CHOICES, CLASSIFICATION_CHOICES, RESULT_CHOICES, PRIORITY_CHOICES } = require('../../../../../../component/form/select/options.json')

export default function AttendanceUnit({ attendance=null, setModalProps=()=> null, fetchAttendancesHistory=() => null }) {
    const history = useHistory()
    const { isHealthProfessional } = useApp()
    const attendanceDate = MaskDataHora(attendance?.start)
    
    const openConsultation = (id) => {
        history.push(`/consultation/${id}`)
    }
    
    const attendancePriority = PRIORITY_CHOICES.find(({ id }) => id === attendance?.priority)
    const attendanceDecision = DECISION_CHOICES?.find(({ id }) => id === attendance?.decision)

    return <div className='AttendanceUnit'>
        <div className='AttendanceUnit-Header'>
            <div>
                <div className='AttendanceUnit-Header-TitleBox'>
                    <b className='AttendanceUnit-Header-TitleBox-PatientName'>{attendance?.person?.name || 'Paciente não encontrado'}</b>
                </div>
                <span className='AttendanceUnit-Header-AltText'>{attendance?.protocol || '-'} em {attendanceDate?.data && attendanceDate?.hora ? `${attendanceDate?.data} ${attendanceDate?.hora}` : '-'}</span>
                <div className='AttendanceUnit-Header-TitleBox-AlertsBox'>
                    { 
                        attendancePriority && attendancePriority.id !== 'NO_PRIORITY'
                        ? <div 
                            className='AttendanceUnit-Header-TitleBox-PriorityBox'
                            style={{ 
                                color: attendancePriority.color, 
                                backgroundColor: attendancePriority.backgroundColor, 
                                border: `1px solid ${attendancePriority.color}`}
                            }
                        >
                            <b>{attendancePriority?.name}</b>
                        </div>
                        : null
                    }
                    {
                        attendanceDecision?.name
                        ? <div 
                            className='AttendanceUnit-AppointmentBox-Subtitle-DecisionBox' 
                            style={{ 
                                'border': `1px solid ${attendanceDecision.color}`,
                                'backgroundColor': attendanceDecision.secondColor,
                                'color': attendanceDecision.color,
                            }}>
                            <b>{attendanceDecision?.name}</b>
                        </div> 
                        : null
                    }
                    {
                        CLASSIFICATION_CHOICES?.find(ob => ob?.id === attendance?.classification)?.name
                        ? <div className='AttendanceUnit-AppointmentBox-Subtitle-DecisionBox'>
                            <b>{CLASSIFICATION_CHOICES?.find(ob => ob?.id === attendance?.classification)?.name}</b>
                        </div>
                        : null
                    }
                    {
                        RESULT_CHOICES?.find(ob => ob?.id === attendance?.result)?.name
                        ? <div className='AttendanceUnit-AppointmentBox-Subtitle-DecisionBox'>
                            <b>{RESULT_CHOICES?.find(ob => ob?.id === attendance?.result)?.name}</b>
                        </div>
                        : null
                    }
                    {
                        attendance?.specialty?.name 
                        ? <div className='AttendanceUnit-AppointmentBox-Subtitle-DecisionBox'>
                            <b>{attendance?.specialty?.name}</b>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
        <div>
            <div className='AttendanceUnit-AppointmentBox-Subtitle'>
                <b>Resumo</b>
            </div>
            <div className='AttendanceUnit-AppointmentBox-Grid'>
                <InfoLine 
                    label='Profissional'
                    value={
                        attendance?.doctor?.name
                        ? <span className='AttendanceUnit-AppointmentBox-Grid-AltText'>{`${attendance?.doctor?.sex === 'F' ? 'Dra. ' : 'Dr. '} ${attendance?.doctor?.name}`}</span>
                        : attendance?.extra_kwargs?.external_doctor_name || '-'
                    }
                />
                {
                    attendance?.health_insurance?.name
                    ? <InfoLine 
                        label='Convênio'
                        value={<span className='AttendanceUnit-AppointmentBox-Grid-AltText'>{attendance?.health_insurance?.name}</span>}
                    />
                    : null
                }
                <InfoLine
                    label='CPF'
                    value={<span className='AttendanceUnit-PatientBox-Grid-AltText'>{attendance?.person?.cpf || '-'}</span>}
                />
            </div>
        </div>
        <div className='AttendanceUnit-ActionRow'>
            {
                isHealthProfessional
                ? <div 
                    className='AttendanceUnit-ActionRow-Btn' 
                    onClick={() => openConsultation(attendance?.id)}
                >
                    <b>Ver atendimento</b>
                </div>
                : null
            }
            <div
                className='AttendanceUnit-ActionRow-Btn' 
                onClick={() => setModalProps({
                    open: true,
                    title: `Editar atendimento | ${MaskDataHora(attendance.start)?.data} | ${MaskDataHora(attendance.start)?.hora}`,
                    content: <NewAppointmentModal
                        initAppointment={attendance}
                        fetchAppointments={fetchAttendancesHistory}
                        setModalInfo={setModalProps}
                    />
                })}
            >
                <b>Editar</b>
            </div>
        </div>
    </div>
}

